import React, { useState } from 'react'
import { BASEIMAGEURL, useAppContext } from '../../../Context/AppContext'

const ViewDeposit = ({ isShow, onClose, data, amount, convertedAmount }) => {
    const { approvedDepositOrReject } = useAppContext();
    const [loader, setLoader] = useState(false);
    const [loader1, setLoader1] = useState(false);


    const isApprovedOrReject = async (status) => {
        if (status == "APPROVED") {
            setLoader(true);
            await approvedDepositOrReject(data._id, status);
            setLoader(false);

        } else {
            setLoader1(true);
            await approvedDepositOrReject(data._id, status);
            setLoader1(false);
        }
    }
    return (
        <div className={`model-box ${isShow ? "show" : "hide"}`}>
            <div className='loginModel'
                style={{
                    height: "auto"
                }}
            >

                <div className='modal-header'>
                    <h4>{"Deposit Request"}</h4>
                </div>
                <div className='modal-body'>
                    <div className='imageBox' >
                        <a style={{ width: "100%", height: "250px" }} href={`${BASEIMAGEURL + data?.image}`} target='_blank'>  <img src={`${BASEIMAGEURL + data?.image}`} style={{ width: "100%", height: "100%" }} alt="" /></a>
                    </div>
                    <div>
                        <input
                            style={{
                                paddingLeft: "15px",
                                paddingRight: "15px",
                                height: "100%",
                                width: "100%",
                                paddingBlock: 10,
                                borderRadius: 4,
                                marginBlock: 10,
                                backgroundColor: "transparent",
                                border: "1px solid #111",
                                outline: "none",
                            }}
                            placeholder='Enter Deposit' value={amount} />
                    </div>
                    <div>
                        <input
                            style={{
                                paddingLeft: "15px",
                                paddingRight: "15px",
                                height: "100%",
                                width: "100%",
                                paddingBlock: 10,
                                borderRadius: 4,
                                marginBlock: 10,
                                backgroundColor: "transparent",
                                border: "1px solid #111",
                                outline: "none",
                            }}
                            placeholder='Enter Convert Amount' value={convertedAmount} />
                    </div>
                </div>
                <div className='modal-footer'>
                    <button onClick={onClose}>Cancel</button>
                    <button
                        style={{ backgroundColor: "red" }}
                        onClick={() => {
                            isApprovedOrReject("REJECT");
                        }}>{loader1 ? "Please Wait.." : "Reject"}</button>
                    <button style={{ paddingInline: 10, width: 100 }} onClick={() => {
                        isApprovedOrReject("APPROVED");
                    }}>{loader ? "Please Wait.." : "Approved"}</button>
                </div>
            </div>
        </div>
    )
}

export default ViewDeposit