import React, { useState } from 'react'
import { BASEIMAGEURL, useAppContext } from '../../../Context/AppContext'

const ViewUser = ({ isShow, onClose, data }) => {
    const { approvedOrReject } = useAppContext();
    const [loader, setLoader] = useState(false);
    const [loader1, setLoader1] = useState(false);


    const isApprovedOrReject = async (status) => {
        if (status == "APPROVED") {
            setLoader(true);
            await approvedOrReject(data._id, status);
            setLoader(false);

        } else {
            setLoader1(true);
            await approvedOrReject(data._id, status);
            setLoader1(false);
        }
    }
    return (
        <div className={`model-box ${isShow ? "show" : "hide"}`}>
            <div className='loginModel'
                style={{
                    height: "auto"
                }}
            >

                <div className='modal-header'>
                    <h4>{"View User"}</h4>
                </div>
                <div className='modal-body'>
                    {/* <div className="form-group"> */}
                    {/* <label htmlFor="">Username</label>
                        <div className="field">
                            <input
                                style={{
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    border: "0px solid",
                                    outline: "none",
                                }}
                                disabled
                                value={data?.username}
                                placeholder="Enter Password"

                            />
                        </div>
                    </div> */}
                    <div className="form-group">
                        <label htmlFor="">Email</label>
                        <div className="field">
                            <input
                                style={{
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    border: "0px solid",
                                    outline: "none",
                                    color: "#111"

                                }}
                                disabled
                                value={data?.email}
                                placeholder="Enter Password"

                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Id Card Number</label>
                        <div className="field">
                            <input
                                style={{
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    border: "0px solid",
                                    outline: "none",
                                    color: "#111"
                                }}
                                disabled
                                value={data?.cnic?.number}
                                placeholder="Enter Password"

                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            marginTop: 20
                        }}
                    >
                        <div id="imageBorder" class="container">
                            <input
                                disabled
                                accept='image/*'
                                type="file" hidden name='frontImage' id='frontImage' />
                            <label htmlFor='frontImage'>
                                <div id="imageContainer">
                                    {(data?.cnic.front != null || data?.cnic.front != "") ?
                                        <div id="dropbox">
                                            <a style={{width:"157px",display:'flex',alignItems:"center",justifyContent:'center'}} href={`${BASEIMAGEURL + data?.cnic.front}`} target='_blank'>  <img style={{width:'100%'}} src={BASEIMAGEURL + data?.cnic.front} /></a>
                                        </div>
                                        : null}
                                </div>
                            </label>

                        </div>
                        <div id="imageBorder" class="container">
                            <input
                                disabled
                                // onChange={onPickBackImage}
                                accept='image/*'
                                type="file" hidden name='backImage' id='backImage' />
                            <label htmlFor='backImage'>
                                <div id="imageContainer">
                                    {(data?.cnic.back != null || data?.cnic.back != "") ?
                                        <div id="dropbox">
                                            <a style={{width:"157px",display:'flex',alignItems:"center",justifyContent:'center'}}  href={`${BASEIMAGEURL + data?.cnic.back}`} target='_blank'>      <img style={{width:'100%'}} src={BASEIMAGEURL + data?.cnic.back} /></a>
                                        </div>
                                        : null}
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <button onClick={onClose}>Cancel</button>
                    <button
                        style={{ backgroundColor: "red" }}
                        onClick={() => {
                            isApprovedOrReject("REJECT");
                        }}>{loader1 ? "Please Wait.." : "Reject"}</button>
                    <button style={{}} onClick={() => {
                        isApprovedOrReject("APPROVED");
                    }}>{loader ? "Please Wait.." : "Approved"}</button>
                </div>
            </div>
        </div>
    )
}

export default ViewUser