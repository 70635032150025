import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logo from "../logo.png";
import './style.css';
import { toast } from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const Register = () => {
    const [state, setState] = useState({
        username: '',
        email: '',
        password: '',
        cPassword: '',
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const navigate = useNavigate();

    // Handle input change
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
        });
        setErrors({
            ...errors,
            [e.target.id]: '', // Clear error for the specific field
        });
    };

    // Validate inputs
    const validate = () => {
        const tempErrors = {};

        if (!state.username) tempErrors.username = 'Username is required.';
        if (!state.email) tempErrors.email = 'Email is required.';
        else if (!/\S+@\S+\.\S+/.test(state.email)) tempErrors.email = 'Email is invalid.';
        if (!state.password) tempErrors.password = 'Password is required.';
        else if (state.password.length < 6)
            tempErrors.password = 'Password must be at least 6 characters.';
        if (!state.cPassword) tempErrors.cPassword = 'Please confirm your password.';
        else if (state.cPassword !== state.password)
            tempErrors.cPassword = 'Passwords do not match.';

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) return;

        setIsSubmitting(true);
        try {
            const refCode = window.localStorage.getItem("@RefCode") ?? null;
            const response = await axios.post(`${process.env.REACT_APP_REST_URL}/user/create`, { ...state, referredBy: refCode });
            toast.success('Registration Successful!');
            if (response.status === 200) {
                window.localStorage.setItem("@userTokenId", response.data.data?._id);
                navigate("/user/dashboard", { replace: true });
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Something went wrong. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="containerLogin">
            <div className="login-wrapper">
                <div className="welcome-section">
                    <div className="logo">
                        <img src={Logo} alt="" />
                    </div>
                    <h2>Get Started</h2>
                    <div className='crtP'>
                        <p>Create a new account, fast and easy.</p>
                    </div>
                    <div className="illustration">
                        <img
                            src="https://cryptitan.live/build/assets/register-illustration.e4eb5069.png"
                            style={{ width: '300px' }}
                            alt="Illustration"
                        />
                    </div>
                </div>
                <div className="form-section">
                    <div className="getStarted">
                        <p>
                            Already have an account?{' '}
                            <Link to={'/auth/login'} className="registerText">
                                Login
                            </Link>
                        </p>
                    </div>
                    <div className="fom-box">
                        <h2>Get started in few steps</h2>
                        <p>We need some information</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="username">
                                    <input
                                        type="text"
                                        id="username"
                                        placeholder="Username *"
                                        value={state.username}
                                        onChange={handleChange}
                                    />
                                    {errors.username && <span className="error">{errors.username}</span>}
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder="Email *"
                                        value={state.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <span className="error">{errors.email}</span>}
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" className="password-field">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        placeholder="Password *"
                                        value={state.password}
                                        onChange={handleChange}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => setShowPassword(!showPassword)}
                                        className="show-password-btn"
                                    >
                                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                                    </button>
                                    {errors.password && <span className="error">{errors.password}</span>}
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="cPassword" className="password-field">
                                    <input
                                        type={showCPassword ? "text" : "password"}
                                        id="cPassword"
                                        placeholder="Confirm Password *"
                                        value={state.cPassword}
                                        onChange={handleChange}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => setShowCPassword(!showCPassword)}
                                        className="show-password-btn"
                                    >
                                        {showCPassword ? <FaEye /> : <FaEyeSlash />}
                                    </button>
                                    {errors.cPassword && <span className="error">{errors.cPassword}</span>}
                                </label>
                            </div>
                            <button
                                type="submit"
                                className="login-button"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Sign Up'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
