import React, { useRef, useState } from "react";
import Logo from "../logo.png";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const Login = () => {
    const [state, setState] = useState({
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // Toggle state for password visibility
    const navigate = useNavigate();

    // Handle input change
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
        });
        setErrors({
            ...errors,
            [e.target.id]: "", // Clear error for the specific field
        });
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    // Validate inputs
    const validate = () => {
        const tempErrors = {};
        if (!state.email) tempErrors.email = "Email is required.";
        else if (!/\S+@\S+\.\S+/.test(state.email))
            tempErrors.email = "Email is invalid.";
        if (!state.password) tempErrors.password = "Password is required.";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) return;

        setIsSubmitting(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_REST_URL}/user/login`,
                state
            );
            toast.success("Login Successful!");
            if (response.status === 200) {
                window.localStorage.setItem(
                    "@userTokenId",
                    response.data.data?._id
                );
                navigate("/user/dashboard", { replace: true });
            }
        } catch (error) {
            toast.error(
                error.response?.data?.message ||
                "Something went wrong. Please try again."
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="containerLogin">
            <div className="login-wrapper">
                <div className="welcome-section">
                    <div className="logo">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <h2>Hi, welcome back.</h2>

                    <div className="illustration">
                        <img src="/images/loginImage.png" alt="Illustration" />
                    </div>
                </div>
                <div className="form-section">
                    <div className="getStarted">
                        <p style={{ marginInline: 10 }}>
                            Don't have an account?
                            <Link to={"/auth/register"} className="registerText">
                                Get started
                            </Link>
                        </p>
                    </div>
                    <div className="fom-box">
                        <h2>Sign in to Fx Nex</h2>
                        <p style={{ marginBottom: 20 }}>Enter your details below.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder="Email *"
                                        value={state.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && (
                                        <span className="error">{errors.email}</span>
                                    )}
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" style={{ position: "relative" }}>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        placeholder="Password *"
                                        value={state.password}
                                        onChange={handleChange}
                                    />
                                    {/* Show/Hide Password Button */}
                                    <span
                                        onClick={togglePasswordVisibility}
                                        style={{
                                            position: "absolute",
                                            right: "10px",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            cursor: "pointer",
                                            color: "gray",
                                            fontSize: "1.7rem",
                                        }}
                                    >
                                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                                    </span>
                                    {errors.password && (
                                        <span className="error">{errors.password}</span>
                                    )}
                                </label>
                            </div>
                            <div className="form-actions">
                                <div>
                                    <input type="checkbox" id="remember-me" />
                                    <label htmlFor="remember-me"> Remember me</label>
                                </div>
                                <Link to="/auth/forgot-password" className="forgot-password">
                                    Forgot password?
                                </Link>
                            </div>
                            <button
                                type="submit"
                                className="login-button"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Logging in..." : "Login"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
