import React from 'react'
import { BASEIMAGEURL, useDashboardContext } from '../Context/DashboardContext'
import { IoMdEye } from 'react-icons/io';

const DepositTable = () => {
    const { allDeposit } = useDashboardContext();
    return (
        <div className=''>
            <table className='market-table '>
                <thead className='table-head'>
                    <tr className='table-row table-title'>
                        <th className="table-heading" scope="col">Id</th>
                        <th className="table-heading" scope="col">Plan</th>
                        <th className="table-heading" scope="col">Amount</th>
                        <th className="table-heading" scope="col">image</th>
                        <th className="table-heading" scope="col">Status</th>
                        <th className="table-heading" scope="col">Date</th>

                        {/* <th className="table-heading" scope="col">Action</th/> */}

                    </tr>
                </thead>
                <tbody style={{ position: allDeposit?.length > 0 ? "inherit" : "relative" }}>
                    {
                        allDeposit == null ?
                            <p>Please Wait</p> :
                            allDeposit?.length > 0
                                ? allDeposit?.map((d, k) => {
                                    return (
                                        <tr className="table-row" key={k}>
                                            <td>{k + 1}</td>
                                            <td>{d?.plan?.invest?.from != 0 && d?.plan?.invest?.from + "$"} ({d?.plan.type})</td>
                                            <td>{d?.amount} {d?.coins?.bankType == "CRYPTO" ?"$":"PKR"}</td>
                                            <td>
                                                <img src={`${BASEIMAGEURL + d?.image}`} style={{ width: 70, height: 70, borderRadius: 5 }} />
                                            </td>
                                            <td>{d?.userplan?.isApproved}</td>
                                            <td>{d?.createdAt.split("T")[0]}</td>
                                            {/* <td>
                                                <IoMdEye />
                                            </td> */}


                                        </tr>
                                    );
                                }) : <div style={{
                                    position: 'absolute',
                                    width: "100%"
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: "center",
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        marginTop: "4rem"
                                    }}>
                                        <p>No Data Found</p>
                                        <img style={{ width: 100 }} src='images/nodataImage.png' />
                                    </div>
                                </div>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default DepositTable