import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BiSolidImage } from 'react-icons/bi';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import { BaseApi, useAppContext } from '../../Context/AppContext';
import HistoryTable from './Tables/HistoryTable';

import { useNavigate } from 'react-router-dom';
import country from '../../countries.json';

// 03353586125

const Profile = () => {
  const { userInfo } = useAppContext();
  return (
    <div>
      <Tabs userInfo={userInfo} />
    </div>
  )
}



const Tabs = ({ userInfo }) => {
  const [activeTab, setActiveTab] = useState("Tab1");


  const renderContent = () => {
    switch (activeTab) {
      case "Tab1":
        return <ShowProfile userInfo={userInfo} />;
      case "Tab2":
        return <AccountVerification />;
      // case "Tab3":
      // return <div;
      case "Tab4":
        return <ForgotPassword />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="tab-buttons">
        <button
          className={activeTab === "Tab1" ? "active" : ""}
          onClick={() => setActiveTab("Tab1")}
        >
          Account Info
        </button>
        <button
          className={activeTab === "Tab2" ? "active" : ""}
          onClick={() => setActiveTab("Tab2")}
        >
          Verification
        </button>
        {/* <button
          className={activeTab === "Tab3" ? "active" : ""}
          onClick={() => setActiveTab("Tab3")}
        >
          History
        </button> */}
        <button
          className={activeTab === "Tab4" ? "active" : ""}
          onClick={() => setActiveTab("Tab4")}
        >
          Change Password
        </button>
      </div>
      <div className=" referralBox">{renderContent()}</div>
    </div>
  );
}
const ShowProfile = ({ userInfo }) => {
  const baseUrl = "https://fxnex.org/referral";

  const [state, setState] = useState({
    username: '',
    number: '',
    country: 'Select Country'
  });

  const [loader, setLoader] = useState(false);


  useEffect(() => {
    console.log(userInfo);
    if (userInfo) {
      // handleImageUploadFromURL(process.env.REACT_APP_Image_URL + userInfo?.profileImage);
      setState((prevState) => ({
        ...prevState,
        username: userInfo?.username,
      }));
      setState((prevState) => ({
        ...prevState,
        country: userInfo?.country,
      }));

    }
  }, [])

  const navigate = useNavigate();

  const updateInfo = async () => {
    const formData = new FormData();
    formData.append("username", state.username);
    formData.append("country", state.country);
    if (state.profileImage) {
      formData.append("profileImage", state.profileImage);
    }
    setLoader(true);
    await BaseApi.put(`/user/updateInfo/${userInfo?._id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        toast.success("Update Info Success");
        navigate("/user/dashboard");
      })
      .catch((er) => {
        if (er.response?.status === 400 || er.response?.status === 404) {
          toast.error(`${er.response?.data['message']}`);
        }
      });

    setLoader(false);
  };


  // const [file, setFile] = useState(null);


  // const downloadImage = (url) => {
  //   fetch(url)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       setFile(URL.createObjectURL(blob));
  //     })
  //     .catch(error => console.error('Error downloading the image:', error));
  // };

  // const handleImageUploadFromURL = (url) => {
  //   axios.get(url, { responseType: 'blob' })  // Get the image as a blob
  //     .then((response) => {
  //       const file = new File([response.data], 'profileImage.jpg', { type: response.data.type });

  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setState((prevState) => ({
  //           ...prevState,
  //           profileImagePreview: reader.result, // Set the local preview of the image
  //         }));
  //       };
  //       reader.readAsDataURL(file);  // Read the image as a data URL for preview
  //     })
  //     .catch(error => console.error('Error downloading the image:', error));

  // };







  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setState((prevState) => ({
          ...prevState,
          profileImage: file,
          profileImagePreview: reader.result, // Set local preview
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div className='form-row'>
        {/* Profile Image Section */}
        <div className="input-row">
          <div className="input-container" style={{ marginRight: 0 }}>
            <label htmlFor="profile-image">Profile Image</label>
            <input
              type="file"
              accept="image/*"
              id="profile-image"
              onChange={handleImageUpload}
            />
            {userInfo?.profileImage != "" || userInfo?.profileImage != null ?
              (

                <div>
                  <img
                    src={process.env.REACT_APP_Image_URL + userInfo?.profileImage}
                    alt="Profile Preview"
                    style={{ width: "100px", marginTop: 10, height: "100px", borderRadius: "50%" }}
                  />
                </div>
              )
              : state.profileImagePreview && (
                <div>
                  <img
                    src={state.profileImagePreview}
                    alt="Profile Preview"
                    style={{ width: "100px", marginTop: 10, height: "100px", borderRadius: "50%" }}
                  />
                </div>
              )}
          </div>
        </div>
        <div className='input-row'>
          <div class="input-container">
            <label for="account-number">Username</label>
            <input type="text" value={state.username}
              onChange={(d) => {
                setState((prevState) => ({
                  ...prevState,
                  username: d.target.value,
                }));

              }}
              id="account-number" placeholder="Enter Username" />
          </div>
          <div class="input-container">
            <label for="account-number">Email</label>
            <input type="text" id="account-number" value={userInfo?.email} placeholder="Enter Email" />
          </div>
        </div>
        <div className='input-row'>
          <div class="input-container">
            <label for="account-number">Number</label>
            <input type="tel"

              id="account-number" value={userInfo?.number}
              // onChange={(d) => {
              //   setState((prevState) => ({
              //     ...prevState,
              //     number: d.target.value,
              //   }));

              // }}
              placeholder="Enter Number" />

            <div id="recaptcha-container"></div>

          </div>
          <div class="input-container">
            <Select2
              // multiple
              data={country.map(c => c?.country)}
              options={
                {
                  placeholder: 'Select Country',
                }
              }
              value={state.country}
              onChange={(d) => {
                setState((prevState) => ({
                  ...prevState,
                  country: d.target.value,
                }));
              }}
            />
          </div>
        </div>
        <div className='input-row'>
          <div class="input-container">
            <label for="account-number">Referral Code</label>
            <input type="text" id="account-number" value={`${baseUrl}?ref=${encodeURIComponent(userInfo?.referralCode)}`} placeholder="Enter referral code" />
          </div>
          <div class="input-container">
            {/* <label for="account-number">Email</label>
            <input type="text" id="account-number" placeholder="Enter Email" /> */}
          </div>
        </div>

        <div className='btn-row'>
          <button onClick={updateInfo}>{loader ? "Please Wait..." : "Update"}</button>
        </div>
      </div>
    </div>
  );
}



const ForgotPassword = () => {
  const { userInfo } = useAppContext();
  const [state, setState] = useState({
    password: '',
    newPassword: '',
    cPassword: ''
  });

  const [errors, setErrors] = useState({
    password: '',
    newPassword: '',
    cPassword: ''
  });

  const onUpdatePassword = async () => {
    const { password, newPassword, cPassword } = state;

    // Validate password
    if (password.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, password: 'Current password is required' }));
      toast.error('Error: Current password is required');
      return;
    }

    // Validate new password
    if (newPassword.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, newPassword: 'New password is required' }));
      toast.error('Error: New password is required');
      return;
    }

    // Validate new password length
    if (newPassword.length < 8) {
      setErrors((prevErrors) => ({ ...prevErrors, newPassword: 'New password must be at least 8 characters long' }));
      toast.error('Error: New password must be at least 8 characters long');
      return;
    }

    // Validate confirm password
    if (cPassword.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, cPassword: 'Confirm password is required' }));
      toast.error('Error: Confirm password is required');
      return;
    }

    // Validate confirm password match
    if (newPassword !== cPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, cPassword: 'Passwords do not match' }));
      toast.error('Error: Passwords do not match');
      return;
    }

    // If all validations pass, update password
    try {
      // Your password update logic here
    } catch (error) {
      console.error(error);
      toast.error('Error updating password: ' + error.message);
    }
  };
  return (
    <div>
      <div className='form-row'>
        <div className='input-row'>
          <div class="input-container">
            <label for="account-number">Email</label>
            <input value={userInfo?.email} type="text" id="account-number" placeholder="Enter Email" />
          </div>
          <div class="input-container">
            <label for="account-number">Password</label>
            <input
              value={state.password}
              onChange={(e) => {
                setState(p => ({
                  ...p,
                  password: e.target.value
                }))
              }}

              type="password" id="account-numer" placeholder="Enter Old Password" />
          </div>
        </div>
        <div className='input-row'>
          <div class="input-container">
            <label for="account-number">New Password</label>
            <input
              value={state.newPassword}
              onChange={(e) => {
                setState(p => ({
                  ...p,
                  newPassword: e.target.value
                }))
              }}



              type="password" id="account-number" placeholder="Enter New Password" />
          </div>
          <div class="input-container">
            <label for="account-number">Confirm Password</label>
            <input
              value={state.cPassword}
              onChange={(e) => {
                setState(p => ({
                  ...p,
                  cPassword: e.target.value
                }))
              }}

              type="password" id="account-nmber" placeholder="Enter Confirm Password" />
          </div>
        </div>
        <div className='btn-row'>
          <button onClick={onUpdatePassword}>Update</button>
        </div>
      </div>
    </div>
  );
}


const AccountVerification = () => {
  const { userInfo } = useAppContext();

  const [idCardFront, setIdCardFront] = useState(null);
  const [idCardBack, setIdCardBack] = useState(null);
  const [idCardNumber, setIdCardNumber] = useState('')

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (type === "front") setIdCardFront(file);
    if (type === "back") setIdCardBack(file);
  };









  const [loader, SetLoader] = useState(false);
  const [loader1, SetLoader1] = useState(false);


  const handleSubmit = async (e) => {
    // e.preventDefault();

    if (userInfo?.isApproved == "APPROVED") return;
    if (idCardNumber == "") {
      toast.error("Please Enter Id Card Number");
      return;
    }

    // Create FormData object to send files
    const formData = new FormData();
    formData.append("idCardFront", idCardFront);
    formData.append("idCardBack", idCardBack);
    formData.append('idCardNumber', idCardNumber)

    try {
      const response = await BaseApi.put(`/user/updateCnic/${userInfo?._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Files uploaded successfully!");
      window.location.reload();
    } catch (error) {
      toast.error("Error uploading files!");
    }
  };



  const [state, setState] = useState({
    number: '',
    otp: '',
  });

  const [state1, setState1] = useState({
    email: '',
    otp: '',
  });


  useEffect(() => {
    // console.log(userInfo);
    if (userInfo) {
      setState((prevState) => ({
        ...prevState,
        number: userInfo?.number || '',
      }));

      if (userInfo?.isEmailVerified) {
        setState1({
          otp: userInfo.otpMailCode || '',
        });
      }

      if (userInfo?.isnumberVerified) {

        setState((prevState) => ({
          ...prevState,
          otp: userInfo.otpNumberCode || '',
        }));
      }

      setIdCardNumber(userInfo?.cnic?.number);
    }
  }, [userInfo]);






  const sendOtp = async () => {
    if (!/^\+\d{10,15}$/.test(state.number)) {
      toast.error("Please enter a valid phone number.");
      return;
    }
    SetLoader1(true);
    await BaseApi.post(`/user/sendNumberOtp/${userInfo?._id}`)
      .then((res) => {
        toast.success("Sent OTP Code to your Email Please Check The Email.");
      }).catch((er) => {
        console.log(er);
      });
    SetLoader1(false);


  };


  const verifyOtp = async () => {
    if (userInfo?.isnumberVerified) {
      return;
    }
    if (state.otp == "") {
      toast.error("Please enter a valid OTP code.");
      return;
    }


    await BaseApi.put(`/user/updateNumberOtp/${userInfo?._id}`, { number: state.number, otp: state.otp })
      .then((res) => {
        toast.success("OTP Verified Success");
        window.location.reload();
      }).catch((er) => {
        if (er.status == 400) {
          toast.error(`${er.response?.data['message']}`)
        }
      });


  };



  const sendOtp1 = async () => {
    setState1(true);
    await BaseApi.post(`/user/sendMailCode/${userInfo?._id}`)
      .then((res) => {
        toast.success("Sent Verification Code to your Email Please Check The Email.");
      }).catch((er) => {
        console.log(er);
      });
    setState1(false);

  };


  const verifyOtp1 = async () => {
    if (userInfo?.isEmailVerified) {
      return;
    }
    if (state1.otp == "") {
      toast.error("Please enter a valid Verification code.");
      return;
    }

    await BaseApi.put(`/user/updateMailOtp/${userInfo?._id}`, { otp: state1.otp })
      .then((res) => {
        toast.success("OTP Verified Success");
        window.location.reload();
      }).catch((er) => {
        // console.log(er.status);
        if (er.status == 400) {
          toast.error(`${er.response?.data['message']}`)
        }
      })

  };

  return (
    <div className='accountVerify'>
      <div className='form-row'>
        {/* <div className='input-row'> */}
        <div class="input-container">
          <label for="account-number">Email</label>
          <input type="text" id="account-number"
            disabled={userInfo?.isEmailVerified}
            value={userInfo?.email} placeholder="Enter Email" />
        </div>
        <div class="input-container">
          <label for="account-number">OTP Code</label>
          <input type="number"
            value={state1.otp}
            onChange={(d) => {
              setState1((prevState) => ({
                ...prevState,
                otp: d.target.value,
              }));

            }}
            disabled={userInfo?.isEmailVerified}
            id="account-number" placeholder="Enter OTP Code" />
        </div>
        {/* </div> */}

        <div className='btn-row'>
          <button  disabled={userInfo?.isEmailVerified} onClick={sendOtp1}>{userInfo?.isEmailVerified ? "Verified" : loader ? "Please Wait..." : "Send Code"}</button>
          <div style={{ width: 13 }}></div>
          <button  disabled={userInfo?.isEmailVerified} onClick={verifyOtp1}>Update</button>
        </div>
        {/* <div className='input-row' style={{ marginTop: 30 }}> */}
        <div class="input-container">
          <label for="account-number">Number</label>
          <PhoneInput
            placeholder="Enter phone number"
            value={state.number}
            onChange={(d) => {
              setState((prevState) => ({
                ...prevState,
                number: d,
              }));
            }}
            international // Enables international country selection
            searchPlaceholder="Search countries..." // Custom search placeholder

          />
        </div>
        <div class="input-container">
          <label for="account-number">Otp Code</label>
          <input type="number" id="account-number"
            value={state.otp}
            onChange={(d) => {
              setState((prevState) => ({
                ...prevState,
                otp: d.target.value,
              }));

            }}
            disabled={userInfo?.isnumberVerified}
            placeholder="Enter Otp Code" />

        </div>
        {/* </div> */}
        <div className='btn-row'>
          <button
            disabled={userInfo?.isnumberVerified}
            onClick={sendOtp}>{userInfo?.isnumberVerified ? "Verified" : loader1 ? "Please Wait..." : "Send Code"}</button>
          <div style={{ width: 13 }}></div>
          <button
            disabled={userInfo?.isnumberVerified}
            onClick={verifyOtp}>Update</button>
        </div>
      </div>
      <div className='form-row cicnMainBpx' style={{}} >
        <div className='cinBox'>
          <div class="image-upload">
            <label for="file-input">
              {
                idCardFront != null ?
                  <img style={{ "width": "100%", height: '116px' }} src={window.URL.createObjectURL(idCardFront)} /> :
                  <>
                    <BiSolidImage className='upload-icon' />
                    <span>Upload Id Card Front</span></>
              }
            </label>
            <input
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  setIdCardFront(e.target.files[0])
                }
              }}
              id="file-input" type="file" accept="image/*" />
          </div>
          <div class="image-upload">
            <label for="file-input1">
              {

                idCardBack != null ?
                  <img style={{ "width": "100%", height: '116px' }} src={window.URL.createObjectURL(idCardBack)} /> :
                  <>
                    <BiSolidImage className='upload-icon' />
                    <span>Upload Id Card Back</span></>
              }
            </label>
            <input
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  setIdCardBack(e.target.files[0])
                }
              }}
              id="file-input1" type="file" accept="image/*" />
          </div>

        </div>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 10
        }}>
          {
            userInfo?.cnic?.front != null && userInfo?.cnic?.front != "" &&
            <img style={{
              width: "180px",
              height: "125px",
              borderRadius: '11px'
            }} src={process.env.REACT_APP_Image_URL + userInfo?.cnic?.front} />

          }

          {
            userInfo?.cnic?.back != null && userInfo?.cnic?.back != "" &&
            <img style={{
              width: "180px",
              height: "125px",
              borderRadius: '11px'
            }} src={process.env.REACT_APP_Image_URL + userInfo?.cnic?.back} />

          }
        </div>
        <div class="input-container" style={{ marginTop: 30 }}>
          <label for="account-number">Id Card Number</label>
          <input value={idCardNumber} onChange={(e) => setIdCardNumber(e.target.value)} type="text" id="account-number" placeholder="Enter Id Card Number" />
        </div>

        <div className='btn-row' style={{ justifyContent: 'space-between' }}>
          {userInfo?.isApproved != "APPROVED" && <div style={{ width: "auto" }}>
            Your Request is Under Process
          </div>}
          <div style={{ width: 10 }}></div>
          {/* userInfo?.isApproved == "PENDING" ? "Pending" :  */}
          <button onClick={handleSubmit}
          // disabled={userInfo?.isApproved == "APPROVED"}
          >{"Update"}</button>
        </div>
      </div>
    </div>
  );
}

export default Profile