import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useAppContext } from '../../../Context/AppContext';

const AddCoinAddress = ({ isShow, onClose, data }) => {
    const [title, setTitle] = useState("");
    const [address, setAddress] = useState("");
    const [loader, setLoader] = useState(false);
    const { addCoin } = useAppContext();



    useEffect(() => {
        if (data) {
            setTitle(data?.accountName);
            setAddress(data?.accountNumber);
            // setTitle(data?.)
        }
    }, [])


    const onAddCoin = async () => {
        if (title == "" || address == "") {
            toast.error("Please Fill The Form");
        } else {
            setLoader(true);
            await addCoin({ title, coinAddress: address });
            setLoader(false);
            onClose();
        }
    }
    return (
        <div className={`model-box ${isShow ? "show" : "hide"}`}>
            <div className='loginModel'
                style={{
                    height: "auto"
                }}
            >

                <div className='modal-header'>
                    <h3>{"Payment Method"}</h3>
                </div>
                <div className='modal-body'>
                    <div className="form-group">
                        <label htmlFor="">Name</label>
                        <div className="field">
                            <input
                                style={{
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    border: "0px solid",
                                    outline: "none",
                                    color: "#111"
                                }}
                                placeholder="Enter Title"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Address</label>
                        <div className="field">
                            <input
                                style={{
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    border: "0px solid",
                                    outline: "none",
                                    color: "#111"
                                }}
                                placeholder="Enter Address"
                                onChange={(e) => setAddress(e.target.value)}
                                value={address}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Payment Method</label>
                        <div className="field">
                            <select>
                                <option>Select Payment Method</option>
                                <option value="1">EASYPAISA</option>
                                <option value="2">JAZZCASH</option>
                                <option value="3">BANK</option>
                                <option value="4">CRYPTO</option>

                            </select>
                        </div>
                    </div>

                </div>

                <div className='modal-footer'>
                    <button onClick={onClose}>Cancel</button>
                    <button onClick={() => {
                        onAddCoin()
                    }}>{loader ? "Please Wait..." : "Submit"}</button>
                </div>
            </div>
        </div>
    )
}

export default AddCoinAddress