import React from "react";

function Terms() {
  return (
    <> 
  
  <title >Terms and Conditions</title>
  <style
  />
  <div style={{  padding:"50px"}} > 
    <h1  style={{marginBottom:"30px",marginTop:"80px",color:"white"}}>Terms and Conditions</h1>
    <h2  style={{marginBottom:"30px",color:"white"}}>1. Investment Packages</h2>
    <p>
      <h3  style={{marginTop:"30px",color:"white"}}>Package 1:</h3>
      <br />
      Investment Range: $50 to $50k
      <br />
      Profit: 20% to 22%
      <br />
      Claim: You can earn daily profits, but claims are only allowed once within a year.
    </p>
    <p>
      <h3  style={{marginTop:"30px",color:"white"}} >Package 2:</h3>
      <br />
      Investment Range: $50 to $50k
      <br />
      Profit: 8% to 10%
      <br/>
      Claim: You can earn daily profits, but claims are only allowed once within a month.
    </p>
    {/* <p>
      <h3 style={{marginTop:"30px"}}>Package 3:</h3>
      <br />
      Investment Range: $1010 to $1B
      <br />
      Profit: 7% to 8%
    </p> */}
    <h2 style={{marginTop:"30px",color:"white"}}>2. Profit and Return</h2>
    <p>
      - Profit will be credited to your account everyday.
      <br />- The invested amount will be returned after a period of 4 months.
    </p>
    <h2 style={{marginTop:"30px",color:"white"}}>3. User Responsibilities</h2>
    <p>
      - Users must ensure that they provide accurate information during the
      registration process.
      <br />
      - It is the user's responsibility to keep their account credentials
      secure.
      <br />- Users are prohibited from engaging in any fraudulent or illegal
      activities on the platform.
    </p>
    <h2 style={{marginTop:"30px",color:"white"}}>4. Risks</h2>
    <p>
      - Investment in financial markets carries inherent risks, and past
      performance is not indicative of future results.
      <br />- Users should carefully consider their risk tolerance before
      participating.
    </p>
    <h2 style={{marginTop:"30px",color:"white"}}>5. Termination</h2>
    <p>
      - The platform reserves the right to terminate or suspend accounts for
      violation of terms or any suspicious activities.
    </p>
    <h2 style={{marginTop:"30px",color:"white"}} >6. Disclaimer</h2>
    <p>
      - [Your Company Name] is not liable for any losses incurred due to market
      fluctuations or unforeseen events.
      <br />- The information provided on the platform is for informational
      purposes only and should not be considered financial advice.
    </p>
    <h2 style={{marginTop:"30px",color:"white"}}>7. Changes to Terms</h2>
    <p>
      - [Your Company Name] reserves the right to update or modify these terms
      and conditions at any time without prior notice.
    </p>
    <h2 style={{marginTop:"30px",color:"white"}}>8. Contact Information</h2>
    <p>
      For any inquiries or concerns regarding these terms, please contact us at{" "}
      <a style={{marginBottom:"30px"}}  href="mailto:info@fxnex.org">info@fxnex.org</a>
    </p>
  </div>
</>


// smtpout.secureserver.net port : 465 Noman03173818
  );
}

export default Terms;
