import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BaseApi, useAppContext } from '../../Context/AppContext';

const WithDraw = () => {
    const { allWithdraw } = useAppContext();
    const [isView, setIsView] = useState(false);
    const [user, setUser] = useState(null);

    const [searchUser, setSearchUser] = useState(allWithdraw);
    useEffect(() => {
        setSearchUser(allWithdraw);
    }, [allWithdraw]);

    const search = (text) => {
        if (text.length > 0) {
            const search = allWithdraw.filter(
                (e) =>
                    (e?.user?.username.toLowerCase().includes(text.toLowerCase()) ||
                        e.user?.email.toLowerCase().includes(text.toLowerCase())) &&
                    e
            );
            // console.log(search);
            if (search.length > 0) {
                setSearchUser(search);
            } else {
                setSearchUser([]);
            }
        } else {
            setSearchUser(allWithdraw);
        }
    };



    const approvedDepositOrReject = async (id, status) => {
        try {
            const response = await BaseApi.put("/withdraw/approvedOrReject", {
                id: id,
                status: status,
            });
            if (response.status == 200) {
                toast.success("Withdraw " + status + " Success");
                window.location.reload();
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (
                    error.response.status == 400 ||
                    error.response.status == 404 ||
                    error.response.status == 401
                ) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            }
        }
    };


    const [amountUSD, setAmountUSD] = useState(null);
    const [convertedAmount, setConvertedAmount] = useState(null);
    const onConvert = async (amount, bankType) => {
        if (bankType == "CRYPTO") {
            setAmountUSD(`${amount}$`)
            setConvertedAmount(`${amount}$`)

        } else {
            const res = await convertCurrency(amount, "PKR", "USDT");
            if (res) {
                setAmountUSD(`${amount}$`)
                setConvertedAmount(`${res?.convertedAmount?.toFixed(2)}PKR`)
            }
        }
    }




    const convertCurrency = async (amount, fromCurrency, toCurrency) => {
        const API_KEY = 'cur_live_0GnM3441KwjkDAnyPyjTWtvwBmo4SnTZpdJvBird';
        const API_URL = `https://api.currencyapi.com/v3/latest?apikey=${API_KEY}`;
        try {
            // Fetch exchange rates
            const response = await axios.get(API_URL);
            const rates = response.data.data;
            // Get exchange rates for the currencies
            const fromRate = rates[fromCurrency]?.value;
            const toRate = rates[toCurrency]?.value;
            if (!fromRate || !toRate) {
                throw new Error('Invalid currency code');
            }
            // console.log((fromRate));

            // Calculate the converted amount
            const convertedAmount = (fromRate / toRate) * amount;
            return {
                convertedAmount,
                fromRate,
                toRate,
            };
        } catch (error) {
            console.error('Error converting currency:', error.message);
            return null;
        }
    };
    return (
        <div>
            <div className="search-box">
                <input
                    placeholder="Search here"
                    onChange={(e) => search(e.target.value)}
                />
                {/* <button>Add New</button> */}
            </div>
            <div className="table-box">
                <table className=" market-table">
                    <thead className="table-head">
                        <tr className="table-row table-title">
                            <th className="table-heading" scope="col">
                                #
                            </th>
                            <th className="table-heading" scope="col">
                                UserName
                            </th>

                            <th className="table-heading" scope="col">
                                Amount
                            </th>
                            <th className="table-heading" scope="col">
                                Account Number
                            </th>
                            <th className="table-heading" scope="col">
                                Account Name
                            </th>
                            <th className="table-heading" scope="col">
                                Payment Method
                            </th>
                            <th className="table-heading" scope="col">
                                Status
                            </th>
                            <th className="table-heading" scope="col">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {searchUser == null ? (
                            <p>Please Wait...</p>
                        ) : searchUser.length > 0 ? (
                            searchUser?.map((d, k) => {
                                return (
                                    <tr className="table-row" key={k}>
                                        <td>{k + 1}</td>
                                        <td>{d?.user?.username}</td>
                                        <td>{d?.amount}$</td>
                                        <td>{d?.accountNumber}</td>
                                        <td>{d?.accountName}</td>
                                        <td>{d?.coins?.type}</td>
                                        <td>{d?.status ? "Approved" : "Pending"}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <i
                                                onClick={async () => {
                                                    setUser(d);
                                                    await onConvert(d.amount, d?.coins?.type)
                                                    setIsView(true);
                                                    // await approvedDepositOrReject(d?._id, true)
                                                }}
                                                className="bx bx-edit"
                                                style={{ cursor: "pointer", background: "", padding: 5, borderRadius: 100 }}
                                            />
                                            {/* <i

                                                onClick={async () => {
                                                    // await approvedDepositOrReject(d?._id, false)
                                                }}
                                                className="bx bx-trash"
                                                style={{ cursor: "pointer", marginInline: 10, background: "red", padding: 5, borderRadius: 100 }}
                                            /> */}

                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <p>No Data Founded</p>
                        )}
                    </tbody>
                </table>
            </div>
            <ViewDeposit isShow={isView} onClose={() => setIsView(false)} data={user}
                amount={amountUSD}
                convertedAmount={convertedAmount}
                approvedDepositOrReject={approvedDepositOrReject}
            />
        </div>
    );
}



const ViewDeposit = ({ isShow, onClose, data, amount, convertedAmount, approvedDepositOrReject }) => {
    // const { approvedDepositOrReject } = useAppContext();
    const [loader, setLoader] = useState(false);
    const [loader1, setLoader1] = useState(false);


    const isApprovedOrReject = async (status) => {
        if (status == "APPROVED") {
            setLoader(true);
            await approvedDepositOrReject(data._id, status)
            setLoader(false);
        } else {
            setLoader1(true);
            await approvedDepositOrReject(data._id, status);
            setLoader1(false);
        }
        //     setLoader(true);
        //     await approvedDepositOrReject(data._id, status);
        //     setLoader(false);

        // } else {
        //     setLoader1(true);
        //     await approvedDepositOrReject(data._id, status);
        //     setLoader1(false);
        // }
    }
    return (
        <div className={`model-box ${isShow ? "show" : "hide"}`}>
            <div className='loginModel'
                style={{
                    height: "auto"
                }}
            >

                <div className='modal-header'>
                    <h4>{"Withdraw Request"}</h4>
                </div>
                <div className='modal-body'>
                <div>
                        <input
                            style={{
                                paddingLeft: "15px",
                                paddingRight: "15px",
                                height: "100%",
                                width: "100%",
                                paddingBlock: 10,
                                borderRadius: 4,
                                marginBlock: 10,
                                backgroundColor: "transparent",
                                border: "1px solid #111",
                                outline: "none",
                            }}
                            placeholder='Enter Convert Amount' value={amount} />
                    </div>
                    <div>
                        <input
                            style={{
                                paddingLeft: "15px",
                                paddingRight: "15px",
                                height: "100%",
                                width: "100%",
                                paddingBlock: 10,
                                borderRadius: 4,
                                marginBlock: 10,
                                backgroundColor: "transparent",
                                border: "1px solid #111",
                                outline: "none",
                            }}
                            placeholder='Enter Deposit' value={convertedAmount} />
                    </div>
                   
                </div>
                <div className='modal-footer'>
                    <button onClick={onClose}>Cancel</button>
                    <button
                        style={{ backgroundColor: "red" }}
                        onClick={() => {
                            isApprovedOrReject("REJECT");
                        }}>{loader1 ? "Please Wait.." : "Reject"}</button>
                    <button style={{ paddingInline: 10, width: 100 }} onClick={() => {
                        isApprovedOrReject("APPROVED");
                    }}>{loader ? "Please Wait.." : "Approved"}</button>
                </div>
            </div>
        </div>
    )
}

export default WithDraw