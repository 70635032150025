import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASEAPIURL, useAppContext } from '../../Context/AppContext';
import toast from 'react-hot-toast';
import { useDashboardContext } from './Context/DashboardContext';

import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';

const banks = [
  {
    "id": 1,
    "name": "Habib Bank Limited (HBL)",
    "image": "https://example.com/images/hbl.png"
  },
  {
    "id": 2,
    "name": "United Bank Limited (UBL)",
    "image": "https://example.com/images/ubl.png"
  },
  {
    "id": 3,
    "name": "National Bank of Pakistan (NBP)",
    "image": "https://example.com/images/nbp.png"
  },
  {
    "id": 4,
    "name": "Meezan Bank",
    "image": "https://example.com/images/meezan.png"
  },
  {
    "id": 5,
    "name": "Allied Bank Limited (ABL)",
    "image": "https://example.com/images/abl.png"
  },
  {
    "id": 6,
    "name": "Bank Alfalah",
    "image": "https://example.com/images/alfalah.png"
  },
  {
    "id": 7,
    "name": "Faysal Bank",
    "image": "https://example.com/images/faysal.png"
  },
  {
    "id": 8,
    "name": "Standard Chartered Pakistan",
    "image": "https://example.com/images/standard_chartered.png"
  },
  {
    "id": 9,
    "name": "Askari Bank",
    "image": "https://example.com/images/askari.png"
  },
  {
    "id": 10,
    "name": "Bank of Punjab (BOP)",
    "image": "https://example.com/images/bop.png"
  },
  {
    "id": 11,
    "name": "Silk Bank",
    "image": "https://example.com/images/silk.png"
  },
  {
    "id": 12,
    "name": "Summit Bank",
    "image": "https://example.com/images/summit.png"
  },
  {
    "id": 13,
    "name": "JS Bank",
    "image": "https://example.com/images/jsbank.png"
  },
  {
    "id": 14,
    "name": "Soneri Bank",
    "image": "https://example.com/images/soneri.png"
  },
  {
    "id": 15,
    "name": "MCB Bank",
    "image": "https://example.com/images/mcb.png"
  }
];

const coins = [
  {
    "name": "Bitcoin",
    "symbol": "BTC",
    "icon": "https://www.iconarchive.com/icons/cjdowner/cryptocurrency/128/Bitcoin-icon.png"
  },
  {
    "name": "TRON",
    "symbol": "TRX",
    "icon": "https://www.iconarchive.com/icons/cjdowner/cryptocurrency/128/Ethereum-icon.png"
  },
  // {
  //   "name": "Binance Coin",
  //   "symbol": "BNB",
  //   "icon": "https://www.iconarchive.com/icons/cjdowner/cryptocurrency/128/Binance-Coin-icon.png"
  // },
  {
    "name": "Tether",
    "symbol": "USDT",
    "icon": "https://www.iconarchive.com/icons/cjdowner/cryptocurrency/128/Tether-icon.png",
    "type": "TRC20"
  },
  // {
  //   "name": "Ripple",
  //   "symbol": "XRP",
  //   "icon": "https://www.iconarchive.com/icons/cjdowner/cryptocurrency/128/Ripple-icon.png"
  // }
];



const Payment = () => {
  const [paymentMethod, setPaymentMethod] = useState(1);
  const location = useLocation();
  const [paymentMethods, setPaymentMethods] = useState(null);
  const { allPlans } = useAppContext();
  const onChangeCard = (index) => {
    handleCardClick();
    setPaymentMethod(index);
  }

  useEffect(() => {
    getAllPaymentMethod();
  }, [])


  const getAllPaymentMethod = async () => {
    try {
      const response = await axios.get(`${BASEAPIURL}/paymentMethod/web`);
      if (response.status === 200) {
        setPaymentMethods(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }




  const easypaisa = paymentMethods?.filter(v => v?.type == "EASYPAISA");
  const jazzCash = paymentMethods?.filter(v => v?.type == "JAZZCASH");
  const bank = paymentMethods?.filter(v => v?.type == "BANK");
  const crypto = paymentMethods?.filter(v => v?.type == "CRYPTO");

  const [accNumber, setAccNumber] = useState('');
  const [accName, setAccName] = useState('');
  const [selectBank, setSelectBank] = useState('');

  const [currentCrypto, setCurrentCrypto] = useState("BTC Coin");

  const [planId, setPlanId] = useState('');
  const [amount, setAmount] = useState('');
  const [image, setImage] = useState(null);
  const { userInfo } = useAppContext();
  const { getAllUserDeposit, getAllUserWithDraw } = useDashboardContext();



  const navigate = useNavigate();
  // console.log(crypto?.filter(d=> d.bankAndCoinName==currentCrypto)[0]?.accountNumber)

  const addDeposit = async () => {
    // setAccName(paymentMethod == 4 && crypto[0]?.bankAndCoinName);
    setAccName(paymentMethod == 4 && crypto?.filter(d => d.bankAndCoinName == currentCrypto)[0]?.bankAndCoinName);

    setAccNumber(paymentMethod == 4 && crypto?.filter(d => d.bankAndCoinName == currentCrypto)[0]?.accountNumber);
    if (accNumber == "" || accName == "" || amount == "" || image == null) {
      toast.error("Please Fill All Required Fields");
    } else {
      const formData = new FormData();
      if (paymentMethod == 3 || paymentMethod == 4) {
        if (selectBank == "") {
          toast.error("Please Fill All Required Fields");
          return;
        } else {
          formData.append("bankAndCoinName", selectBank);
        }
      }
      try {
        const paymentMethodId = paymentMethod == 1 ? easypaisa[0]?._id : paymentMethod == 3 ? bank[0]?._id : paymentMethod == 4 ? crypto?.filter(d => d.bankAndCoinName == currentCrypto)[0]?._id : jazzCash[0]?._id;
        formData.append('userId', userInfo?._id);
        formData.append('paymentMethodId', paymentMethodId);
        formData.append('planId', "6750e7369f33df08f03ee504");
        formData.append('amount', amount);
        formData.append('accountNumber', accNumber);
        formData.append('accountName', accName);
        formData.append("image", image);
        const response = await axios.post(`${BASEAPIURL}/userByPlan/create`, formData);
        if (response.status == 200) {
          getAllUserDeposit()

          toast.success("Deposit Request Successfully");
          navigate("/user/dashboard");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }




  const [wAccNumber, setWAccNumber] = useState("");
  const [wAccName, setWAccName] = useState("");
  const [wAmount, setWAmount] = useState("");
  const [wBank, setWBank] = useState("");




  const onWithDrawRequest = async () => {
    // alert("dddd");
    if(userInfo?.isApproved=="PENDING"){
      toast.error("Please Verify Your Number Or Id Card Information");
      return;
    }
    if (wAccNumber == "" || wAccName == "" || wAmount == "") {
      toast.error("Please Fill All Required Fields");
    } else if (userInfo?.totalAmount == 0) {
      toast.error("You Amount is zero therefor you can not withdraw");
    } else {
      if (paymentMethod == 3 || paymentMethod == 4) {
        // alert("ddd");
        if (wBank == "") {
          toast.error("Please Fill All Required Fields");
          return;
        }
      }
      // else {
      if (wAmount < 5) {
        toast.error("You cannot make a withdrawal until your balance reaches $5.");
        return;
      } else if (wAmount > userInfo?.totalAmount) {
        toast.error("You cannot make a withdrawal until your balance reaches.");
        return;
      } else {

        try {
          const paymentMethodId = paymentMethod == 1 ? easypaisa[0]?._id : paymentMethod == 3 ? bank[0]?._id : paymentMethod == 4 ? crypto?.filter(d => d.bankAndCoinName == currentCrypto)[0]?._id : jazzCash[0]?._id;
          const response = await axios.post(`${BASEAPIURL}/withdraw/create`, {
            userId: userInfo?._id,
            amount: wAmount,
            paymentMethodId: paymentMethodId,
            accountNumber: wAccNumber,
            accountName: wAccName,
            bankAndCoinName: wBank,
          });
          if (response.status == 200) {
            getAllUserWithDraw();
            navigate("/user/dashboard");
            toast.success("WidthDraw Request Successfully");
          }
        } catch (error) {
          console.log(error);
        }
      }
      // }
    }

  }


  const formRef = useRef(null);



  const handleCardClick = () => {

    if (formRef.current) {
      formRef.current.scrollTop = formRef.current.scrollHeight;
    }

  };




  const { currencies } = useDashboardContext();



  const convertCurrency = async (amount, fromCurrency, toCurrency) => {
    const API_KEY = 'cur_live_0GnM3441KwjkDAnyPyjTWtvwBmo4SnTZpdJvBird';
    const API_URL = `https://api.currencyapi.com/v3/latest?apikey=${API_KEY}`;
    try {
      // Fetch exchange rates
      const response = await axios.get(API_URL);
      const rates = response.data.data;
      // Get exchange rates for the currencies
      const fromRate = rates[fromCurrency]?.value;
      const toRate = rates[toCurrency]?.value;
      if (!fromRate || !toRate) {
        throw new Error('Invalid currency code');
      }
      // console.log((fromRate));

      // Calculate the converted amount
      const convertedAmount = (fromRate / toRate) * amount;
      return {
        convertedAmount,
        fromRate,
        toRate,
      };
    } catch (error) {
      console.error('Error converting currency:', error.message);
      return null;
    }
  };



  const [disableHover, setDisableHover] = useState(false);

  const handleClick = () => {
    setDisableHover(true);
    const timer = setInterval(() => {
      setDisableHover(false);
      clearInterval(timer);
    }, 60)
  };


  return (
    <div className='container' style={{ overflowY: 'scroll', height: "78vh" }} id="paymentRow" ref={formRef}>
      <div className="payment-row">
        <div className="col-left">
          <h3 style={{ marginTop: 10 }}>{location.pathname == "/user/payment" ? "Deposit Method" : "Withdraw Method"}</h3>
          <div className='account-row'>
            <div onClick={() => { onChangeCard(1) }} className={`account-card ${paymentMethod == 1 && "active"}`}>
              <img src='images/easypaisa-logo.png' />
              <p>Invest with Easypaisa</p>
            </div>
            <div onClick={() => { onChangeCard(2) }} className={`account-card ${paymentMethod == 2 && "active"}`}>
              <img src='images/jazz-logo.png' />
              <p>Invest with Jazz Cash</p>
            </div>
            <div onClick={() => { onChangeCard(3) }} className={`account-card ${paymentMethod == 3 && "active"}`}>
              <img src='images/bank-icon.png' />
              <p>Invest with local Bank</p>
            </div>
            <div onClick={() => { onChangeCard(4) }} className={`account-card ${paymentMethod == 4 && "active"}`}>
              <img src='images/btcCoinIcon.png' />
              <p>Invest with Cryptocurrency</p>
            </div>
          </div>
        </div>
        {location.pathname != "/user/payment" ?
          <div className='col-right' >
            <h3>Additional Info</h3>

            <div className='payment-form'>
              {
                paymentMethod == 3 && <div class="input-container">
                  <div class="dropdown">
                    <button class="dropdown-btn">{wBank != '' ? wBank : "Select an option"}</button>
                    <div class={`${disableHover ? 'dropdown-content-close' : 'dropdown-content'}`}

                    >
                      {banks.map((b, i) => {
                        return (
                          <div
                            onClick={() => {
                              setWBank(b.name)
                              handleClick();
                            }}
                            key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}> {b.name} ({b.name}) <img src={b.icon} alt="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSxA3xHc3GksU-vnfyN054dxwZXLYr2HcK_A&s" /></div>
                        )
                      })
                      }
                    </div>
                  </div>

                </div>
              }

              {
                paymentMethod == 4 && <div class="input-container">
                  <div class="dropdown">
                    <button class="dropdown-btn">{wBank != '' ? wBank : "Select an option"}</button>
                    <div class={`${disableHover ? 'dropdown-content-close' : 'dropdown-content'}`}>
                      {coins.map((b, i) => {
                        return (
                          <div
                            onClick={() => {
                              console.log(b.name);

                              setWBank(b.name)
                              if (b.name == "Bitcoin") {
                                setCurrentCrypto("BTC Coin")
                              } else if (b.name == "TRON") {
                                setCurrentCrypto("tron Coin");

                              } else if (b.name == "Tether") {
                                setCurrentCrypto("tether Coin");
                              }
                              handleClick()
                            }}
                            key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}> {b.name} ({b.symbol}) {b.type && `(${b.type})`} <img src={b.icon} alt="Check" /></div>
                        )
                      })
                      }
                    </div>
                  </div>

                </div>
              }
              <div class="input-container">
                <label for="account-number">{paymentMethod == 4 ? "Coin Address" : "Account Number"}</label>
                <input type="text" onChange={(e) => setWAccNumber(e.target.value)} value={wAccNumber} id="account-number" placeholder={paymentMethod == 4 ? "Enter Coin Address" : "Enter Acc Number"} />
              </div>
              {paymentMethod != 4 && <div class="input-container">
                <label for="account-number">Account Holder Name</label>
                <input type="text" onChange={(e) => setWAccName(e.target.value)} value={wAccName} id="account-number" placeholder="Enter Holder Name" />
              </div>}
              <div class="input-container">
                <label for="account-number">Amount</label>
                <input type="number" onChange={(e) => setWAmount(e.target.value)} value={wAmount} id="account-number" placeholder="Minimum Withdraw 5$ " />
              </div>

              <p style={{ marginBlock: 10 }}>Withdrawal fee 5%</p>
              <button onClick={onWithDrawRequest} type="submit" className="login-button" >
                Withdraw Request
              </button>
            </div>
          </div>
          : <div className='col-right'>
            <h3>Additional Info</h3>

            {
              paymentMethod == 1 || paymentMethod == 2 ? (<div className='payment-form'>
                <div className='input-row'>
                  <div class="input-container">
                    <label for="account-number">Account Number</label>
                    <input type="text" value={accNumber} onChange={(e) => { setAccNumber(e.target.value) }} id="account-number" placeholder="Enter Acc Number" />
                  </div>
                  <div class="input-container">
                    <label for="account-number">Account Name</label>
                    <input type="text" value={accName} onChange={(e) => { setAccName(e.target.value) }} id="account-number" placeholder="Enter Acc Name" />
                  </div>
                  {/* currencies */}
                </div>
                <div className='input-row'>

                  <div class="input-container">
                    <label htmlFor="account-number">Amount & Currency</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // gap: "0.5rem", // Add spacing between the input and dropdown
                      }}
                    >
                      {/* Input Field */}
                      <input
                        type="number"
                        style={{
                          flex: "0 0 82%",
                          // flex: 1, // Takes the remaining available space
                          // padding: "0.5rem",
                          border: "1px solid #ccc",
                          borderRight: "0px solid #ccc",
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                          borderTopLeftRadius: "4px",
                          borderBottomLeftRadius: "4px",
                        }}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                      {/* Select2 Dropdown */}
                      <div style={{ flex: "0 0 15%", borderRadius: "5px" }} className="Cur-wrapper">

                        <Select2
                          // style={{
                          //   flex: "0 0 15%",
                          //   // Fixed width of 120px for the dropdown
                          // }}
                          data={currencies != null ? Object.values(currencies).map((c) => ({
                            id: c?.code,
                            text: `(${c.code})`,
                          })) : []}
                          style={{
                            flex: "0 0 15%",
                            outline: 'none',
                            borderTopLeftRadius: "0px",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                            borderTopLeftRadius: "0px",
                            // background: "#666666"
                            // Fixed width of 120px for the dropdown
                          }}
                          className="custom-select2"

                          // className="Cur"
                          value="PKR"
                          options={{
                            placeholder: "PKR",
                            style: {
                              flex: "0 0 10%",
                              outline: 'none',
                              borderTopLeftRadius: "0px",
                              borderTopRightRadius: "5px",
                              borderBottomRightRadius: "5px",
                              borderTopLeftRadius: "0px",
                              // background: "#666666"
                              // Fixed width of 120px for the dropdown
                            }
                          }}
                          disabled={true} // This disables the dropdown

                          onChange={async (d) => {
                            if (parseInt(amount) <= 0) {

                            } else {
                              // await convertCurrency(parseInt(amount), "USDT", d.target.value)
                              //   .then((c) => {
                              //     console.log(c);
                              //   }).catch((er) => {
                              //     console.log(er);
                              //   });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>



                  {/* <div class="input-container">
                    <label for="account-number">Amount</label>
                    <input type="text" value={amount} onChange={(e) => { setAmount(e.target.value) }} id="account-number" placeholder="Enter Amount" />
                  </div>
                   */}

                  {/* <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                    <select
                      // value={selectedCurrency}
                      // onChange={handleCurrencyChange}
                      style={{ padding: "0.5rem", borderRadius: "4px" }}
                    >
                      <option value="" disabled>Select Currency</option>
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="GBP">GBP</option>
                      <option value="JPY">JPY</option>
                    </select>

                    <input
                      type="number"
                      placeholder="Amount"
                      // value={amount}
                      // onChange={handleAmountChange}
                      style={{ padding: "0.5rem", borderRadius: "4px" }}
                    />
                  </div> */}

                  {/* <div className='input-row'>

                    <div class="input-container">
                      <label for="account-number">Account Name</label>
                      <input type="text" value={accName} onChange={(e) => { setAccName(e.target.value) }} id="account-number" placeholder="Enter Acc Name" />
                    </div>

                    <div class="input-container">
                      <label for="account-number">Account Name</label>
                      <input type="text" value={accName} onChange={(e) => { setAccName(e.target.value) }} id="account-number" placeholder="Enter Acc Name" />
                    </div>
                  </div> */}
                  {/* currencies */}
                </div>

                <div class="input-container" style={{ marginTop: '-0.0rem' }}>
                  <label for="account-number">Account Holder Number</label>
                  <input type="text"
                    value={
                      paymentMethod === 1
                        ? easypaisa?.[0]?.accountNumber || ''
                        : jazzCash?.[0]?.accountNumber || ''
                    }
                    id="account-number" placeholder="Enter Acc Number" />
                </div>
                <div class="input-container">
                  <label for="account-number">Account Holder Name</label>
                  <input type="text" id="account-number"
                    value={
                      (paymentMethod === 1
                        ? easypaisa?.[0]?.accountName || ''
                        : jazzCash?.[0]?.accountName || '') +
                      (paymentMethod === 1 ? ' (easypaisa)' : ' (jazzcash)')
                    }
                    placeholder="Enter Holder Name" />
                </div>

                <div class="input-container">
                  <label for="account-number">Invoice Image</label>
                  <input type="file" onChange={(e) => {
                    if (e.target.files?.length > 0) {
                      setImage(e.target.files[0]);
                    }
                  }} id="account-number" placeholder="Enter Holder Name" />
                </div>
                <button onClick={addDeposit} type="submit" className="login-button" >
                  Deposit
                </button>
              </div>) : paymentMethod == 3 ? (
                <div className='payment-form'>
                  <div class="input-container">
                    <div class="dropdown">
                      <button class="dropdown-btn">{selectBank != "" ? selectBank : "Select an option"}</button>
                      <div class={`${disableHover ? 'dropdown-content-close' : 'dropdown-content'}`}>
                        {banks.map((b, i) => {
                          return (
                            <div
                              onClick={() => { setSelectBank(b.name); handleClick() }}
                              key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}> {b.name} <img src={"https://cdn.pixabay.com/photo/2021/06/27/14/42/money-6369029_640.png"} alt="Check" /></div>
                          )
                        })
                        }
                      </div>
                    </div>

                  </div>

                  <div className='input-row'>
                    <div class="input-container">
                      <label for="account-number">Account Number</label>
                      <input type="text" value={accNumber} onChange={(e) => { setAccNumber(e.target.value) }} id="account-number" placeholder="Enter Acc Number" />
                    </div>
                    <div class="input-container">
                      <label for="account-number">Account Name</label>
                      <input type="text" value={accName} onChange={(e) => { setAccName(e.target.value) }} id="account-number" placeholder="Enter Acc Name" />
                    </div>
                  </div>
                  <div className='input-row'>
                    {/* <div class="input-container">
                      <label for="account-number">Amount</label>
                      <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} id="account-number" placeholder="Enter Amount" />
                    </div> */}
                    <div class="input-container">
                      <label htmlFor="account-number">Amount & Currency</label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // gap: "0.5rem", // Add spacing between the input and dropdown
                        }}
                      >
                        {/* Input Field */}
                        <input
                          type="number"
                          style={{
                            flex: "0 0 82%",
                            // flex: 1, // Takes the remaining available space
                            // padding: "0.5rem",
                            border: "1px solid #ccc",
                            borderRight: "0px solid #ccc",
                            borderTopLeftRadius: "4px",
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                          }}
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                        {/* Select2 Dropdown */}
                        <div style={{ flex: "0 0 15%", borderRadius: "5px" }} className="Cur-wrapper">

                          <Select2
                            // style={{
                            //   flex: "0 0 15%",
                            //   // Fixed width of 120px for the dropdown
                            // }}
                            data={Object.values(currencies).map((c) => ({
                              id: c?.code,
                              text: `(${c.code})`,
                            }))}
                            disabled={true} // This disables the dropdown

                            style={{
                              flex: "0 0 15%",
                              outline: 'none',
                              borderTopLeftRadius: "0px",
                              borderTopRightRadius: "5px",
                              borderBottomRightRadius: "5px",
                              borderTopLeftRadius: "0px",
                              // background: "#666666"
                              // Fixed width of 120px for the dropdown
                            }}
                            className="custom-select2"

                            // className="Cur"
                            value="PKR"
                            options={{
                              placeholder: "PKR",
                              style: {
                                flex: "0 0 10%",
                                outline: 'none',
                                borderTopLeftRadius: "0px",
                                borderTopRightRadius: "5px",
                                borderBottomRightRadius: "5px",
                                borderTopLeftRadius: "0px",
                                // background: "#666666"
                                // Fixed width of 120px for the dropdown
                              }
                            }}

                            onChange={async (d) => {
                              if (parseInt(amount) <= 0) {

                              } else {
                                // await convertCurrency(parseInt(amount), "USDT", d.target.value)
                                //   .then((c) => {
                                //     console.log(c);
                                //   }).catch((er) => {
                                //     console.log(er);
                                //   });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="input-container" style={{ marginTop: '-0.0rem' }}>
                    <label for="account-number">Account  Number</label>
                    <input type="text" id="account-number" value={bank[0]?.accountNumber} placeholder="Enter Acc Number" />
                  </div>
                  <div class="input-container">
                    <label for="account-number">Account Holder Name</label>
                    <input type="text" id="account-number" value={bank[0]?.accountName + (` (${bank[0]?.bankAndCoinName})`)} placeholder="Enter Holder Name" />
                  </div>

                  <div class="input-container">
                    <label for="account-number">Invoice Image</label>
                    <input onChange={(e) => {
                      if (e.target.files?.length > 0) {
                        setImage(e.target.files[0]);
                      }
                    }} type="file" id="account-number" placeholder="Enter Holder Name" />
                  </div>
                  <button onClick={addDeposit} type="submit" className="login-button" >
                    Deposit
                  </button>
                </div>
              ) : <div className='payment-form'>
                <div class="input-container">
                  <div class="dropdown">
                    <button class="dropdown-btn">{selectBank != '' ? selectBank : "Select an option"}</button>
                    <div class={`${disableHover ? 'dropdown-content-close' : 'dropdown-content'}`}
                    // style={{ display: `${disableHover && "none"}` }}
                    >
                      {coins?.map((b, i) => {
                        return (
                          <div
                            onClick={() => {
                              handleClick()
                              setSelectBank(b?.name);
                              if (b.name == "Bitcoin") {
                                setCurrentCrypto("BTC Coin")
                              } else if (b.name == "TRON") {
                                setCurrentCrypto("tron Coin");
                              } else if (b.name == "Tether") {
                                setCurrentCrypto("tether Coin");
                              }
                            }}
                            key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}> {b.name} ({b.symbol}) {b.type && `(${b.type})`} <img src={b.icon} alt="Check" /></div>
                        )
                      })
                      }

                    </div>
                  </div>

                </div>

                <div class="input-container" style={{ marginTop: '-0.0rem' }}>
                  <label for="account-number">Holder Coin Address</label>
                  <input type="text" id="account-number" value={crypto?.filter(d => d.bankAndCoinName == currentCrypto)[0]?.accountNumber} placeholder="Enter Acc Number" />
                </div>
                <div className='input-row'>
                  {/* <div class="input-container">
                    <label for="account-number">Amount</label>
                    <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} id="account-number" placeholder="Enter Amount" />
                  </div> */}
                  <div class="input-container">
                    <label htmlFor="account-number">Amount & Currency</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // gap: "0.5rem", // Add spacing between the input and dropdown
                      }}
                    >
                      {/* Input Field */}
                      <input
                        type="number"
                        style={{
                          flex: "0 0 82%",
                          // flex: 1, // Takes the remaining available space
                          // padding: "0.5rem",
                          border: "1px solid #ccc",
                          borderRight: "0px solid #ccc",
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                          borderTopLeftRadius: "4px",
                          borderBottomLeftRadius: "4px",
                        }}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                      {/* Select2 Dropdown */}
                      <div style={{ flex: "0 0 15%", borderRadius: "5px" }} className="Cur-wrapper">

                        <Select2
                          disabled={true} // This disables the dropdown

                          // style={{
                          //   flex: "0 0 15%",
                          //   // Fixed width of 120px for the dropdown
                          // }}
                          data={Object.values(currencies).map((c) => ({
                            id: c?.code,
                            text: `(${c.code})`,
                          }))}
                          style={{
                            flex: "0 0 15%",
                            outline: 'none',
                            borderTopLeftRadius: "0px",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                            borderTopLeftRadius: "0px",
                            // background: "#666666"
                            // Fixed width of 120px for the dropdown
                          }}
                          className="custom-select2"

                          // className="Cur"
                          value="USDT"
                          options={{
                            placeholder: "USDT",
                            style: {
                              flex: "0 0 10%",
                              outline: 'none',
                              borderTopLeftRadius: "0px",
                              borderTopRightRadius: "5px",
                              borderBottomRightRadius: "5px",
                              borderTopLeftRadius: "0px",
                              // background: "#666666"
                              // Fixed width of 120px for the dropdown
                            }
                          }}
                          onChange={async (d) => {
                            if (parseInt(amount) <= 0) {

                            } else {
                              // await convertCurrency(parseInt(amount), "USDT", d.target.value)
                              //   .then((c) => {
                              //     console.log(c);
                              //   }).catch((er) => {
                              //     console.log(er);
                              //   });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="input-container">
                  <label for="account-number">Invoice Image</label>
                  <input
                    onChange={(e) => {
                      if (e.target.files?.length > 0) {
                        setImage(e.target.files[0]);
                      }
                    }}
                    type="file" id="account-number" placeholder="Enter Holder Name" />
                </div>
                <button onClick={addDeposit} type="submit" className="login-button" >
                  Deposit
                </button>
              </div>
            }
          </div>}
      </div>
    </div>
  )
}

export default Payment