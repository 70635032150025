import React from 'react'

function Policy() {
  return (
    <>
    <title>About Us</title>
    
    <div style={{padding:"50px"}} >
      <h1 style={{marginBottom:"30px",marginTop:"80px", color:"white" }}>About Us</h1>
      <h2 style={{marginBottom:"30px",color:"white"}} >Our Mission</h2>
      <p>
        At Fx Nex, our mission is to empower individuals to achieve
        financial success through smart and strategic investments. We strive to
        provide a platform that offers transparent and accessible opportunities
        for everyone to grow their wealth.
      </p>
      <h2 style={{marginTop:"30px",color:"white" }} >Who We Are</h2>
      <p>
        Fx Nex is a team of dedicated professionals with a passion
        for finance and technology. With years of experience in the financial
        industry, we aim to revolutionize the way people invest by combining
        cutting-edge technology with sound financial principles.
      </p>
      <h2 style={{marginTop:"30px",color:"white"}}>What Sets Us Apart</h2>
      <p>
        - Transparent and Fair: We believe in transparency and fairness in all our
        dealings. Our investment packages are designed with clear terms, and we
        are committed to ensuring a level playing field for all our users.
      </p>
      <p>
        - Expertise: Our team of financial experts brings a wealth of knowledge
        and experience to guide users through the complexities of the financial
        markets.
      </p>
      <p>
        - Innovation: We leverage the latest technologies to provide a seamless
        and user-friendly experience. Our innovative approach sets us apart in the
        world of online investments.
      </p>
      <h2 style={{marginTop:"30px",color:"white"}}>Contact Us</h2>
      <p>
        Have questions or need assistance? Feel free to reach out to us at{" "}
        <a href="mailto:info@fxnex.org">info@fxnex.org</a>
      </p>
    </div>
  </>
  
  )
}

export default Policy