import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../logo.png";
import "./style.css";

const ForgotPassword = () => {
    const [state, setState] = useState({
        email: "",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [code, setCode] = useState(null);  // Code state
    const [isCode, setIsCode] = useState(false);  // Separate state for isCode
    const navigate = useNavigate();

    // Handle input change
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
        });
        setErrors({
            ...errors,
            [e.target.id]: "", // Clear error for the specific field
        });
    };

    // Validate inputs
    const validate = () => {
        const tempErrors = {};
        if (!state.email) tempErrors.email = "Email is required.";
        else if (!/\S+@\S+\.\S+/.test(state.email))
            tempErrors.email = "Email is invalid.";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) return;
        setIsSubmitting(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_REST_URL}/user/sendCode`,
                state
            );
            toast.success("Send Code Successful!");
            if (response.status === 200) {
                setIsCode(true);
            }
        } catch (error) {
            toast.error(
                error.response?.data?.message ||
                "Something went wrong. Please try again."
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="containerLogin">
            <div className="login-wrapper">
                <div className="welcome-section">
                    <div className="logo">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <h2>Hi, welcome back.</h2>

                    <div className="illustration">
                        <img src="/images/loginImage.png" alt="Illustration" />
                    </div>
                </div>
                <div className="form-section">
                    <div className="getStarted">
                        <p style={{ marginInline: 10 }}>
                            Don't have an account?
                            <Link to={"/auth/register"} className="registerText">
                                Get started
                            </Link>
                        </p>
                    </div>
                    <div className="fom-box">
                        <h2>Forgot Password</h2>
                        <p style={{ marginBottom: 20 }}>Enter your details below.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder="Email *"
                                        value={state.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && (
                                        <span className="error">{errors.email}</span>
                                    )}
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="otpCode" style={{ position: "relative" }}>
                                    <input
                                        type="number"
                                        id="otp"
                                        placeholder="Otp Code *"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </label>
                            </div>
                            <button
                                type="submit"
                                className="login-button"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Logging in..." : "Send Code"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ForgotPassword;
