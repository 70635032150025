import React, { useEffect, useState } from 'react';
import {
  BiLogOutCircle,
  BiMenu,
  BiSolidBox,
  BiSolidCreditCard,
  BiSolidDashboard,
  BiSolidDollarCircle,
  BiSolidUserCircle
} from "react-icons/bi";
import { Link, Outlet, useLocation } from 'react-router-dom';
import Logo from "../../logo.png";
import './mainStyle.css';
import { DashboardContextProvider } from './Context/DashboardContext';
import { useAppContext } from '../../Context/AppContext';
import { MdClose } from 'react-icons/md';

const DashboardLayout = () => {
  const location = useLocation();

  // Function to check if the current path matches
  const isActive = (path) => location.pathname === path;
  const { userInfo,getUserInfo } = useAppContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onOpenMenu = () => {
    const sidebar = document.querySelector(".sidebar"); // or "#sidebar"
    if (sidebar) {
      setIsMenuOpen(true);
      sidebar.style.transform = 'translate(0px, 0px)';
    } else {
      console.error("Sidebar element not found.");
    }
  };
  const onCloseMenu = () => {
    const width = window.innerWidth;
    if (width < 450) {
      const sidebar = document.querySelector(".sidebar"); // or "#sidebar"
      if (sidebar) {
        setIsMenuOpen(false);
        sidebar.style.transform = 'translate(-420px, 0px)';
      } else {
        console.error("Sidebar element not found.");
      }
    }
  };

  useEffect(()=>{getUserInfo()},[])

  return (
    <DashboardContextProvider>
      <div className="layout">
        {isMenuOpen && <div className="sideBack" onClick={onCloseMenu}></div>}

        {/* Sidebar */}
        <aside className="sidebar">
          <div className='sidebarLogo'>
            <img src={Logo} alt="" />
            <MdClose onClick={onCloseMenu} className='closIcon' />
          </div>
          <nav className="sidebar-nav">
            <ul>
              <li>
                <Link
                  to="/user/dashboard"
                  onClick={() => {
                    onCloseMenu()
                  }}
                  className={isActive('/user/dashboard') ? 'active' : ''}
                  style={{ display: "flex", alignItems: 'center' }}
                >
                  <BiSolidDashboard style={{ marginRight: 5 }} />
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  to="/user/payment"
                  onClick={() => {
                    onCloseMenu()
                  }}
                  className={isActive('/user/payment') ? 'active' : ''}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <BiSolidCreditCard style={{ marginRight: 5 }} />
                  Deposit
                </Link>
              </li>
              <li>
                <Link
                  to="/user/withdraw"
                  onClick={() => {
                    onCloseMenu()
                  }}
                  className={isActive('/user/withdraw') ? 'active' : ''}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <BiSolidCreditCard style={{ marginRight: 5 }} />
                  Withdraw
                </Link>
              </li>
              <li>
                <Link
                  to="/user/Package"
                  onClick={() => {
                    onCloseMenu()
                  }}
                  className={isActive('/user/Package') ? 'active' : ''}
                  style={{ display: "flex", alignItems: 'center' }}
                >
                  <BiSolidBox style={{ marginRight: 5 }} />
                  Packages
                </Link>
              </li>

              <li>
                <Link
                  to="/user/referral"
                  onClick={() => {
                    onCloseMenu()
                  }}
                  className={isActive('/user/referral') ? 'active' : ''}
                  style={{ display: "flex", alignItems: 'center' }}
                >
                  <BiSolidDollarCircle style={{ marginRight: 5 }} />
                  Referral
                </Link>
              </li>
              <li>
                <Link
                  to="/user/profile"
                  onClick={() => {
                    onCloseMenu()
                  }}
                  className={isActive('/user/profile') ? 'active' : ''}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <BiSolidUserCircle style={{ marginRight: 5 }} />
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    window.localStorage.removeItem("@userTokenId");
                    window.location.replace("/")
                  }}
                  className={isActive('/logout') ? 'active' : ''}
                  style={{ display: "flex", alignItems: 'center' }}
                >
                  <BiLogOutCircle style={{ marginRight: 5 }} />
                  Logout
                </Link>
              </li>
            </ul>
          </nav>
        </aside>

        {/* Main Content Area */}
        <div className="main-content">
          {/* Header */}
          <header className="header">
            <div className='userprofileBox'>
              <BiSolidUserCircle className='icon profileIcon' />
              <BiMenu onClick={onOpenMenu} className='menuIcon' />
              <div>
                <p>{userInfo?.username}</p>
                <span>{userInfo?.email}</span>
              </div>

            </div>
            <div>
              <p>Total: {userInfo?.totalAmount?.toFixed(2)}$</p>
              {/* <span>2042.4$</span> */}
            </div>
            {/* <button className="profile-button">
              <BiLogOutCircle style={{ marginRight: 5 }} />
              Logout
            </button> */}
          </header>

          {/* Page Content */}
          <div className="content">
            {<Outlet /> || <p>Welcome to the dashboard!</p>}
          </div>
        </div>
      </div>
    </DashboardContextProvider>
  );
};

export default DashboardLayout;
