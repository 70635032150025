import React from "react";
import { useAppContext } from "../Context/AppContext";
import { useNavigate } from "react-router-dom";

const Trand = () => {
  const { allPlans } = useAppContext();
  const yearlyPlans = allPlans?.filter(v => v.type === "yearly");
  const monthlyPlans = allPlans?.filter(v => v.type === "monthly");
  const naivgate = useNavigate("")


  return (
    <section className="section trend" aria-label="crypto trend" data-section>
      <div className="container">
        <div className="trend-tab">


          <div style={{ width: "100%", textAlign: 'center', marginBlock: '2rem' }}>
            <h3 style={{ fontSize: "2.8rem", color: "#fff" }}>Our Packages</h3>
          </div>
          <p className="packageText">If you buy any of the following packages you will get 3x profit in a year</p>

          <div className="tab-content" style={{ marginBlock: '1rem', width: "100%" }}>
            {
              yearlyPlans?.map((v, i) => {
                return (
                  <div onClick={() => {
                    const userId = window.localStorage.getItem("@userTokenId");
                    if (userId == null) {
                      naivgate("/auth/login")
                    } else {
                      naivgate("/user/dashboard")

                    }
                  }} class="package-card" key={i}>
                    <div class="header">
                      <div class="amount">${v?.invest?.from}</div>
                    </div>
                    <div class="percentage">{v?.profit?.from}/{v?.profit?.to}%</div>
                    <div class="sub-header">Yearly Investment Profit</div>
                    <ul class="benefits">
                      <li><span>✔</span> 4% Direct Reward</li>
                      <li><span>✔</span> 8% Level Income</li>
                    </ul>
                  </div>
                )
              })
            }
          </div>


          <p className="packageText" style={{ paddingInline: '3rem' }}>In these packages, you can withdraw your daily profits and also retrieve your initial investment along with the accumulated profit at the end of the month.</p>

          <div className="tab-content" style={{ marginBlock: '1rem', width: "100%" }}>
            {
              monthlyPlans?.map((v, i) => {
                return (
                  <div
                    onClick={() => {
                      const userId = window.localStorage.getItem("@userTokenId");
                      if (userId == null) {
                        naivgate("/auth/login")
                      } else {
                        naivgate("/user/dashboard")

                      }
                    }}
                    class="package-card" key={i}>
                    <div class="header">
                      <div class="amount">${v?.invest?.from}</div>
                    </div>
                    <div class="percentage">{v?.profit?.from}/{v?.profit?.to}%</div>
                    <div class="sub-header">Monthly Investment Profit</div>
                    <ul class="benefits">
                      <li><span>✔</span> 4% Direct Reward</li>
                      <li><span>✔</span> 8% Level Income</li>
                      <li><span>✔</span>24/7 Chat Support</li>
                    </ul>
                  </div>
                )
              })
            }
          </div>

        </div>
      </div>
    </section>
  );
};

export default Trand;
