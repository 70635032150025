import React, { useRef, useState } from "react";
import Modal from "react-modal";
import { useAppContext } from "../Context/AppContext";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";




const data = [{
  id: "131",
  title: "You can invest from",
  invest: {
    from: "$50",
    to: "$300",
  },
  profit: {
    from: "5%",
    to: "6%"
  }
},
{
  id: "131",
  title: "You can invest from",
  invest: {
    from: "$310",
    to: "$1000",
  },
  profit: {
    from: "6%",
    to: "7%"
  }
},
{
  id: "131",
  title: "You can invest from",
  invest: {
    from: "$1010",
    to: "$1B",
  },
  profit: {
    from: "7%",
    to: "8%"
  }
}];


function ProductPage() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);
  const [plan, setPlan] = useState(null);
  const { allPlans, localUser } = useAppContext();
  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setImage(event.target.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleContainerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const naivgate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        // backgroundImage:
        //   "url(https://www.forbes.com/advisor/wp-content/uploads/2023/03/stock_chart.jpeg)",
        height: "100%",
        width: "100%",
        background: "hsl(229, 53%, 18%);",
        paddingTop: "70px"
      }}
    >
      <div className="containerForCard container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
            color: "white",
            // height: "70%",
            width: "100%",
            // background: "red",
          }}
        >
          <h2 className="productTextHeading" style={{ marginTop: '20px', fontSize: "3vw", fontWeight: "bold" }}>
            Fx Nex is a secure and trustworthy platform for investing your
            assets
          </h2>
          <div style={{ height: "40px" }}></div>
          <h4 className="productTitle" style={{ width: "70%", marginBottom: "30px", fontWeight: "300" }}>
            Discover peace of mind with Fx Nex – a secure and reliable platform
            offering a trusted space for your asset investments. Experience
            financial confidence with us.
          </h4>
          {/* <h4 className="productTitle neonText" style={{ width: "70%", marginBottom: "30px", fontWeight: "300" }}>
            Your Amout will be return after 4 months.
          </h4> */}
        </div>

        <div className="card__container">
          {
            allPlans == null ?
              <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                <p>Please Wait...</p>
              </div>
              :

              allPlans?.length > 0 ?
                allPlans?.filter(v => v.type === "yearly")?.map((v, i) => {
                  return (
                    <div
                      onClick={() => {
                        const userId = window.localStorage.getItem("@userTokenId");
                        if (userId == null) {
                          naivgate("/auth/login")
                        } else {
                          naivgate("/user/dashboard")

                        }
                      }}
                      class="package-card" key={i}>
                      <div class="header">
                        <div class="amount">${v?.invest?.from}</div>
                      </div>
                      <div class="percentage">{v?.profit?.from}/{v?.profit?.to}%</div>
                      <div class="sub-header">Yearly Investment Profit</div>
                      <ul class="benefits">
                        <li><span>✔</span> 4% Direct Reward</li>
                        <li><span>✔</span> 8% Level Income</li>
                      </ul>
                    </div>
                  )
                })
                : <p>Plan Not Founded</p>}
        </div>
        <p className="packageText">If you buy any of the following packages you will get 3x profit in a year</p>

        <div className="card__container">
          {
            allPlans == null ?
              <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                <p>Please Wait...</p>
              </div>
              :

              allPlans?.length > 0 ?
                allPlans?.filter(v => v.type === "monthly")?.map((v, i) => {
                  return (
                    <div
                      onClick={() => {
                        const userId = window.localStorage.getItem("@userTokenId");
                        if (userId == null) {
                          naivgate("/auth/login")
                        } else {
                          naivgate("/user/dashboard")

                        }
                      }}
                      class="package-card" key={i}>
                      <div class="header">
                        <div class="amount">${v?.invest?.from}</div>
                      </div>
                      <div class="percentage">{v?.profit?.from}/{v?.profit?.to}%</div>
                      <div class="sub-header">Monthly Investment Profit</div>
                      <ul class="benefits">
                        <li><span>✔</span> 4% Direct Reward</li>
                        <li><span>✔</span> 8% Level Income</li>
                        <li><span>✔</span>24/7 Chat Support</li>
                      </ul>
                    </div>
                  )
                })
                : <p>Plan Not Founded</p>}
        </div>
      </div>
      <InvestModal plan={plan} modalIsOpen={modalIsOpen} closeModal={closeModal} image={image}
        handleContainerClick={handleContainerClick}
        handleFileInputChange={handleFileInputChange}
        fileInputRef={fileInputRef}
      />
    </div>
  );
}


const ChipRow = ({ handleChipClick, selectedChip }) => {

  const chips = ["Bitcoin", "USDT TRC20", "Tron", "Local Banks"]; // Add more chips as needed

  return (
    <div className="chip-row">
      {chips.map((chip, index) => (
        <div
          key={index}
          className={`chip ${selectedChip === chip ? "selected" : ""}`}
          onClick={() => handleChipClick(chip)}
        >
          {chip}
        </div>
      ))}
    </div>
  );
};


const InvestModal = ({ modalIsOpen, closeModal, image, plan, handleFileInputChange, fileInputRef, handleContainerClick }) => {
  const { allCoin, onCreatePlan, localUser } = useAppContext()

  const onCopy = () => { }
  const [selectedChip, setSelectedChip] = useState("Bitcoin");
  const [coinId, setCoinId] = useState(null);
  const [image12, setImage] = useState(null);
  const [withdrawalAddress, setWithdrawalAddress] = useState("");

  const handleChipClick = (chip) => {
    if (chip == "Local Banks") {
      const telegramLink = 'https://wa.me/+97471002856';
      window.open(telegramLink, '_blank');
    } else {
      setSelectedChip(chip);
      const coin = allCoin.filter(e => e.title.toLowerCase() == selectedChip.toLowerCase());
      console.log(coin)
      setCoinId(coin?._id);
    }
  };

  const [loader, setLoader] = useState(false);

  const onCreate = async () => {
    const formData = new FormData();
    const coin = allCoin.filter(e => e.title.toLowerCase() == selectedChip.toLowerCase());
    formData.append("image", image12)
    formData.append("coinId", coin[0]?._id)
    formData.append("userId", localUser?._id)
    formData.append("planId", plan?._id);
    formData.append("type", "DEPOSIT")
    formData.append("withdrawalAddress", withdrawalAddress)
    setLoader(true);
    await onCreatePlan(formData);
    setLoader(false);
    closeModal();
  }


  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="modalStyling"
      style={{
        background: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",

        justifyContent: "center",
        overlay: {
          backgroundColor: "transparent",
          zIndex: 100,
        },
        content: {
          borderRadius: "30px",
          display: "flex",
          flexDirection: "column",
          background: "white",
          justifyContent: "center",
          border: "none",
          position: "absolute",
          marginTop: "50px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "80%",
          boxSizing: "border-box",
          overflow: "hidden",
          width: "60%",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div className="buyHeading"
          style={{ fontSize: "3vw", marginBottom: "20px", color: "black" }}
        >
          You Are Now on Basic Plan
        </div>
        <div className="buyHeadingTag"
          style={{ fontSize: "1.5vw", marginBottom: "20px", width: "80%", color: "black" }}
        >
          You can invest from {plan?.invest?.from} to {plan?.invest?.to} Profit {plan?.profit?.from} to {plan?.profit?.to} Amount Received
          Every 10 Days
        </div>
        <h4 style={{ marginBottom: "10px" }}>Choose Network</h4>
        <h4 style={{ marginBottom: "10px" }}>Deposit Address Below</h4>
        <ChipRow
          handleChipClick={handleChipClick}
          selectedChip={selectedChip}
        />
        {
          allCoin && allCoin?.map((v, i) => {

            return v.title.toLowerCase() == selectedChip.toLowerCase() ? (
              <div
                key={i}
                style={{
                  padding: "10px",
                  background: "#dfdfdf",
                  borderRadius: "5px",
                  marginTop: 20,
                  marginBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h5 className="coinAddress" style={{ fontSize: "1vw", marginRight: "10px" }}>
                  {" "}
                  {v.title} :{" "}
                </h5>
                <h5 className="coinAddress"
                  style={{ fontSize: "1vw", color: "black", marginRight: "10px" }}
                >
                  {v.coinAddress}
                </h5>
                <h5 className="coinAddress" style={{ fontSize: "1vw", cursor: "pointer" }}
                  onClick={onCopy}
                >Copy</h5>
              </div>
            ) : null;
          })
        }
        <label style={{ fontWeight: "400" }} htmlFor="">Enter Your {selectedChip} <label style={{ color: "#202556", fontWeight: "bold" }}>Withdrawal</label>  Address</label>
        <div style={{ width: "50%", marginTop: "10px" }} className="field">
          <div style={{ width: "50%", margin: "0 auto", }} >
            <input

              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
                height: "100%",
                width: "100%",
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                border: "0px solid",
                outline: "none",
              }}
              placeholder="Enter Address Here"
              onChange={(e) => setWithdrawalAddress(e.target.value)}
              value={withdrawalAddress}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            marginTop: 10
          }}
          onClick={handleContainerClick}
        >
          <h4 style={{ fontSize: "1.5vw" }}>
            Upload Here Transaction Screenshot
          </h4>
          <div style={{ width: "20px" }}></div>
          {image ? (
            <img
              src={image}
              alt="Selected"
              style={{
                cursor: "pointer",
                borderRadius: "10px",
                width: "50px",
                height: "50px",
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              src="https://img.freepik.com/premium-vector/gallery-simple-icon-vector-image-picture-sign-neumorphism-style-mobile-app-web-ui-vector-eps-10_532800-801.jpg?size=338&ext=jpg&ga=GA1.1.1880011253.1699488000&semt=ais" // Replace with your default folder image
              alt="Folder"
              style={{
                cursor: "pointer",
                borderRadius: "10px",
                width: "50px",
                height: "50px",
                objectFit: "cover",
              }}
            />
          )}

          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => {
              handleFileInputChange(e);
              if (e.target.files) {
                setImage(e.target.files[0]);
              }
            }}
            ref={fileInputRef}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <h4
            onClick={onCreate}
            style={{
              background: "#8bf1e3",
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              borderRadius: "10px",
              color: "black",
              cursor: "pointer",
            }}
          >
            {loader ? "Please Wait..." : "Invest Now"}
          </h4>
        </div>
      </div>
    </Modal>
  );
}

export default ProductPage;
