import axios, { AxiosError } from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

const AppContext = createContext();


// export const BASEAPIURL = "https://api.fxnex.org/api/v1/";
// export const BASEIMAGEURL = "https://api.fxnex.org/";


export const BASEAPIURL = process.env.REACT_APP_REST_URL;

export const BASEIMAGEURL = process.env.REACT_APP_Image_URL;

export const BaseApi = axios.create({
  baseURL: BASEAPIURL,
});
const AppContextProvider = ({ children }) => {
  const [allPlans, setAllPlans] = useState(null);
  const [allDeposit, setAllDeposit] = useState(null);

  const [allWithdraw, setAllWithdraw] = useState(null);
  const [allCoin, setAllCoin] = useState(null);
  const [localUser, setLocalUser] = useState(null);
  const [localAdmin, setLocalAdmin] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [allPlan, setAllPlan] = useState(null);
  const [allCoins, setAllCoins] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  const [buyPlan, setBuyPlan] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [allUserTransactions, setAllUserTransactions] = useState(null);

  const [allTransaction, setAllTransaction] = useState(null);

  const [isEmailVerify, setIsEmailVerify] = useState(false);
  const [isEmailVerifyLoader, setIsEmailVerifyLoader] = useState(false);

  const [email, setEmail] = useState("");
  useEffect(() => {
    initApp();
  }, []);



  const getUserInfo = async () => {
    const userId = window.localStorage.getItem("@userTokenId");
    try {
      const response = await BaseApi.get(`/user/userById/${userId}`);
      if (response.status == 200 && response.data.data) {
        // console.log(response.data.data);
        setUserInfo(response.data.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onCheck = async () => {
    const user = window.localStorage.getItem("@User");
    if (user != null) {
      // const newUser = JSON.parse(user);
      // setLocalUser(newUser);
      // await getUserById(newUser?._id);
      // await getUserPlan(newUser?._id);
      // await getAllUserHistory(newUser?._id);
    } else {
      setLocalUser(null);
    }
  };
  const onAdminCheck = async () => {
    const user = window.localStorage.getItem("@AdminToken");
    if (user != null) {
      // const newUser = JSON.parse(user);
      setLocalAdmin(user);
      getAllAdminDeposit();
      getAllAdminWithDraw();
    } else {
      setLocalAdmin(null);
    }
  };
  const initApp = async () => {
    onAdminCheck();
    getUserInfo();
    getAllPlan();
    getAllCoinAddress();
  };
  const getAllPlan = async () => {
    try {
      const response = await BaseApi.get("/plan/");
      if (response.status == 200 && response.data.data) {
        setAllPlans(response.data.data);
      } else {
        setAllPlans([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserById = async (id) => {
    try {
      const response = await BaseApi.post("/user/userById", { id });
      if (response.status == 200 && response.data.data) {
        window.localStorage.setItem(
          "@User",
          JSON.stringify(response.data.data)
        );
        // await onCheck();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCoinAddress = async () => {
    try {
      const response = await BaseApi.get("/coinAddress/");
      if (response.status == 200 && response.data.data) {
        setAllCoin(response.data.data);
      } else {
        setAllCoin([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createAccount = async (data, email) => {
    try {
      const response = await BaseApi.post("/user/create", data);
      if (response.status == 200) {
        setEmail(email);
        // window.localStorage.setItem("@User", JSON.stringify(response.data.data));
        toast.success("User Register Success");
        await onCheck();
        const timer = setInterval(() => {
          setIsEmailVerify(true);
          clearInterval(timer);
        }, 1500);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };
  const loginAccount = async (data) => {
    try {
      const response = await BaseApi.post("/user/login", data);
      if (response.status == 200) {
        window.localStorage.setItem(
          "@User",
          JSON.stringify(response.data.data)
        );
        await onCheck();
        toast.success("User Login Success");
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const getAllUser = async () => {
    try {
      const token = window.localStorage.getItem("@AdminToken");

      const response = await BaseApi.get("/user/getAllByAdmin", {
        headers: {
          "Authorization": token
        }
      });
      if (response.status == 200) {
        setAllUsers(response.data["data"]);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };




  const getAllAdminDeposit = async () => {
    try {
      const response = await BaseApi.get("/userByPlan/getAllTransaction");
      if (response.status == 200) {
        setAllDeposit(response.data["data"]);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };



  const getAllAdminWithDraw = async () => {
    try {
      const response = await BaseApi.get("/withdraw/getAllTransaction");
      if (response.status == 200) {
        setAllWithdraw(response.data["data"]);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };



  const getAllAdminPlan = async () => {
    try {
      const response = await BaseApi.get("/plan/adminPlan");
      if (response.status == 200) {
        setAllPlan(response.data["data"]);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };


  const getAllAdminCoin = async () => {
    try {
      const token = window.localStorage.getItem("@AdminToken");

      const response = await BaseApi.get("/paymentMethod/", {
        headers: {
          "Authorization": token
        }
      });
      if (response.status == 200) {
        setAllCoins(response.data["data"]);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const addCoin = async (data) => {
    try {
      const response = await BaseApi.post("/coinAddress/create", data);
      if (response.status == 200) {
        getAllAdminCoin();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const emailVerify = async (code) => {
    try {
      setIsEmailVerifyLoader(true);
      const response = await BaseApi.post("/user/verifyEmail", {
        otp: code,
        email: email,
      });
      if (response.status == 200) {
        // console.log(response.data);
        window.localStorage.setItem(
          "@User",
          JSON.stringify(response.data.data)
        );
        toast.success("User Register Success");
        await onCheck();
        setIsEmailVerify(false);
        getUserById(response.data.data?._id);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
    setIsEmailVerifyLoader(false);
  };

  const approvedOrReject = async (id, status) => {
    try {
      const response = await BaseApi.put("/user/approved-or-reject", {
        id: id,
        status: status,
      });
      if (response.status == 200) {

        // console.log(response.data);
        // window.localStorage.setItem(
        //   "@User",
        //   JSON.stringify(response.data.data)
        // );
        toast.success("User " + status + " Success");
        window.location.reload();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  }

  const approvedDepositOrReject = async (id, status) => {
    try {
      const response = await BaseApi.put("/userByPlan/approvedOrReject", {
        id: id,
        status: status,
      });
      if (response.status == 200) {
        toast.success("Deposit " + status + " Success");
        window.location.reload();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const onCreatePlan = async (data) => {
    try {
      const response = await BaseApi.post("/userByPlan/create", data);
      if (response.status == 200) {
        // console.log(response.data);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const getUserPlanAdmin = async () => {
    try {
      const response = await BaseApi.get("/userByPlan/getUserPlanAdmin");
      if (response.status == 200) {
        // console.log(respons/e.data);
        setUserPlan(response.data.data);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const getAllTransactionAdmin = async () => {
    try {
      const response = await BaseApi.get("/userByPlan/getAllTransaction");
      if (response.status == 200) {
        // console.log(response.data);
        setAllTransaction(response.data.data);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const approvedOrRejectPlan = async (id, status) => {
    try {
      const response = await BaseApi.post("/userByPlan/approvedOrReject", {
        id: id,
        status: status,
      });
      if (response.status == 200) {
        await getUserPlan(response.data.data._id);
        // console.log(response.data);
        toast.success("User " + status + " Success");
        window.location.reload();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };
  const getUserPlan = async (id) => {
    try {
      const response = await BaseApi.get("/userByPlan/getByUser/" + id);
      if (response.status == 200) {
        // console.log(response.data);
        setBuyPlan(response.data.data[0]);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };


  const getAllUserHistory = async (id) => {
    try {
      const response = await BaseApi.get("/userByPlan/getAllTransactionByUser/" + id);
      if (response.status == 200) {
        setAllUserTransactions(response.data.data);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const adminLogin = async (data) => {
    try {
      const response = await BaseApi.post("/admin/login", data);
      if (response.status == 200) {
        window.localStorage.setItem(
          "@AdminToken",
          response.data.token
        );
        // setLocalAdmin(response.data.token);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };
  return (
    <AppContext.Provider
      value={{
        allCoin,
        allPlans,
        localUser,
        createAccount,
        loginAccount,
        getAllUser,
        allUsers,
        getAllAdminPlan,
        allPlan,
        getAllAdminCoin,
        allCoins,
        addCoin,
        isEmailVerify,
        setIsEmailVerify,
        emailVerify,
        isEmailVerifyLoader,
        approvedOrReject,
        onCreatePlan,
        getUserPlanAdmin,
        userPlan,
        getAllTransactionAdmin,
        allTransaction,
        approvedOrRejectPlan,
        buyPlan,
        setLocalAdmin,
        localAdmin,
        adminLogin,
        setAllUserTransactions,
        allUserTransactions,
        BaseApi,
        userInfo,
        allDeposit,
        approvedDepositOrReject,
        allWithdraw,
        getUserInfo
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);
export { useAppContext, AppContextProvider };
