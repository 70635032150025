import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Login from "./Login";
import { useAppContext } from "../Context/AppContext";
import ViewProfile from "./ViewProfile";
import VerifyEmail from "./VerifyEmail";
import Logo from "../logo.png";
import PlanView from "./PlanView";
import toast from "react-hot-toast";
const Header = ({ isLogged }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isShow, setIsShow] = useState(false);
  const { localUser, isEmailVerify, setIsEmailVerify } = useAppContext();

  // const navbar = document.querySelector("[data-navbar]");
  // const navbarLinks = document.querySelectorAll("[data-nav-link]");
  // const navToggler = document.querySelector("[data-nav-toggler]");

  const menu = () => {
    const navbar = document.querySelector("[data-navbar]");
    const navbarLinks = document.querySelectorAll("[data-nav-link]");
    const navToggler = document.querySelector("[data-nav-toggler]");
    navbar.classList.toggle("active");
    navToggler.classList.toggle("active");
    document.body.classList.toggle("active");
  };
  // const toggleNavbar = function () {
  //   navbar.classList.toggle("active");
  //   navToggler.classList.toggle("active");
  //   document.body.classList.toggle("active");
  // }

  // addEventOnElem(navToggler, "click", toggleNavbar);

  // const closeNavbar = function () {
  //   navbar.classList.remove("active");
  //   navToggler.classList.remove("active");
  //   document.body.classList.remove("active");
  // }

  // addEventOnElem(navbarLinks, "click", closeNavbar);

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        zIndex: "1000",
        marginTop: "20px",
      }}
    >
      <header className="header" data-header>
        <div className="container">
          <a
            href="javascript:void(0)"
            onClick={() => {
              window.location.href = ("/");
            }}
            className="logo"
          >
            <img
              src={Logo}
              style={{ padding: "10px", width: "70px" }}
              alt="FxNex logo"
            />
          </a>

          <nav className="navbar" data-navbar>
            <ul className="navbar-list">
              <li className="navbar-item">
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    window.location.href = ("/");
                  }}
                  className={`navbar-link ${pathname == "/" && "active"}`}
                  data-nav-link
                >
                  Home
                </a>
              </li>

              <li className="navbar-item">
                <a
                  onClick={() => {
                    window.location.href = ("/plans");
                  }}
                  href="javascript:void(0)"
                  className={`navbar-link ${pathname == "/plans" && "active"}`}
                  data-nav-link
                >
                  Packages
                </a>
              </li>
              {/* <Link to="/policy"> */}
              <li className="navbar-item">
                <a href="javascript:void(0)"
                  onClick={() => {
                    window.location.href = "/policy";
                  }}
                  className="navbar-link" data-nav-link>
                  About Us
                </a>
              </li>
              {/* </Link> */}

              {/* <Link to="/terms"> */}
              <li className="navbar-item">
                <a href="javascript:void(0)"
                  // onClick={()=>}
                  onClick={() => {
                    window.location.href = "/terms";
                  }}
                  className="navbar-link" data-nav-link>
                  Terms & Conditions
                </a>
              </li>
              {/* </Link> */}
              {/* <Link to="/contact"> */}
              <li className="navbar-item">
                <a href="javascript:void(0)"
                  onClick={() => {
                    window.location.href = "/contact";
                  }}
                  className="navbar-link" data-nav-link>
                  Contact
                </a>
              </li>
              {/* </Link> */}
            </ul>
          </nav>

          <button
            className="nav-toggle-btn"
            aria-label="Toggle menu"
            data-nav-toggler
            onClick={menu}
          >
            <span className="line line-1"></span>
            <span className="line line-2"></span>
            <span className="line line-3"></span>
          </button>

          {!isLogged ? (
            // <a
            // href="javascript:void(0)"
            // onClick={() => {
            //   // navigate("/login");
            //   setIsShow(true);
            // }}
            // className="btn btn-outline"
            // >
            // Login
            // </a>
            <Link className="btn btn-outline" to={'/auth/login'} >Login</Link>
          ) : (
            <PorfileAvatr />
          )}
        </div>
        <Login isShow={isShow} onClose={() => setIsShow(false)} />

        <VerifyEmail
          isShow={isEmailVerify}
          onClose={() => setIsEmailVerify(false)}
        />
      </header>
    </div>
  );
};

export const PorfileAvatr = () => {
  const { localUser } = useAppContext();
  const [isShow, setIsShow] = useState(false);
  const { buyPlan } = useAppContext();
  const [isPlanView, setIsPlanView] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="profileBox" style={{ width: 'auto' }}>
      <div className="profile">
        <img
          src="https://static.thenounproject.com/png/363633-200.png"
          alt=""
        />
        {/* <h3 style={{ fontSize: "15px" }}>
          {localUser?.username.toUpperCase()}
        </h3> */}
      </div>
      <div className="profile-box" style={{ width: 150, right: 0, left: "-7rem" }}>
        {/* <button
          onClick={() => {
            if (buyPlan != null) {
              setIsPlanView(true);
            } else {
              toast.error("You have not purchased any plan")
            }
          }}
        >
          Plan
        </button> */}

        {/* <Link to="/user/dashboard" > */}
        <button
          onClick={() => {
            const token = window.localStorage.getItem("@userTokenId")
            if (token != null) {
              navigate('/user/dashboard');
            } else {
              toast.error("Please login to access user Dashboard");
            }
          }}
        >
          <i class="fas fa-th-large"></i>
          Dashboard
        </button>
        {/* </Link> */}
        <button
          onClick={() => {
            window.localStorage.removeItem("@userTokenId");
            window.location.reload();
          }}
        >
          <i class="fa-solid fa-right-from-bracket"></i>
          Logout
        </button>
      </div>

      <ViewProfile isShow={isShow} onClose={() => setIsShow(false)} />

      <PlanView isShow={isPlanView} onClose={() => setIsPlanView(false)} />
    </div>
  );
};
export default Header;
