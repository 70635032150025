import React, { useEffect, useState } from 'react'
import DepositTable from './Tables/DepositTable';
import WithdrawTable from './Tables/WithDrawTable';
import ReferralTable from './Tables/ReferralTable';
import DriectTable from './Tables/DirectTable';
import { useDashboardContext } from './Context/DashboardContext';
import axios from 'axios';

const Dashboard = () => {
  const { allDirectReward, allDeposit, allWithDraw, allCurrentProfit } = useDashboardContext();
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalDeposit1, setTotalDeposit1] = useState(0);

  const totalAmountOfDirect = allDirectReward?.reduce((sum, item) => sum + item.amount, 0);
  // const totalAmountOfWithdraw = allWithDraw?.reduce((sum, item) => sum + parseFloat(item.amount), 0);
  //   const totalAmountOfDeposit = allDeposit?.reduce((sum, item) => sum + parseFloat(item.amount), 0);
  const totalAmountOfDeposit = allDeposit?.reduce((sum, item) => {
    // Add condition, e.g., only sum items with status 'approved'
    if (item?.userplan?.isApproved === 'APPROVED') {
      return sum + parseFloat(item.amount || 0); // Ensure amount is parsed correctly, default to 0 if undefined
    }
    return sum; // Skip this item if condition is not met
  }, 0);

   //   const totalAmountOfDeposit = allDeposit?.reduce((sum, item) => sum + parseFloat(item.amount), 0);
   const totalAmountOfWithdraw = allWithDraw?.reduce((sum, item) => {
    // Add condition, e.g., only sum items with status 'approved'
    if (item?.status) {
      return sum + parseFloat(item.amount || 0); // Ensure amount is parsed correctly, default to 0 if undefined
    }
    return sum; // Skip this item if condition is not met
  }, 0);


  useEffect(() => {
    if (totalAmountOfDeposit) {
      convertAmount();
    }


    if (totalAmountOfWithdraw) {
      convertAmount1();
    }

  }, [totalAmountOfDeposit,totalAmountOfWithdraw])


  const convertAmount = async () => {
    const con = await convertCurrency(totalAmountOfDeposit, "USDT", "PKR");
    if (con) {
      setTotalDeposit(con?.convertedAmount);
    }
  }

  const convertAmount1 = async () => {
    const con = await convertCurrency(totalAmountOfWithdraw, "USDT", "PKR");
    if (con) {
      setTotalDeposit1(con?.convertedAmount);
    }
  }

  const convertCurrency = async (amount, fromCurrency, toCurrency) => {
    const API_KEY = 'cur_live_0GnM3441KwjkDAnyPyjTWtvwBmo4SnTZpdJvBird';
    const API_URL = `https://api.currencyapi.com/v3/latest?apikey=${API_KEY}`;
    try {
      // Fetch exchange rates
      const response = await axios.get(API_URL);
      const rates = response.data.data;
      // Get exchange rates for the currencies
      const fromRate = rates[fromCurrency]?.value;
      const toRate = rates[toCurrency]?.value;
      if (!fromRate || !toRate) {
        throw new Error('Invalid currency code');
      }
      // console.log((fromRate));

      // Calculate the converted amount
      const convertedAmount = (fromRate / toRate) * amount;
      return {
        convertedAmount,
        fromRate,
        toRate,
      };
    } catch (error) {
      console.error('Error converting currency:', error.message);
      return null;
    }
  };

  return (
    <div className='dashboard-box'>
      <div className='dashboard-row'>
        <div className='dashboard-card'>
          <img src='https://cdn3d.iconscout.com/3d/premium/thumb/deposit-3d-icon-download-in-png-blend-fbx-gltf-file-formats--money-finance-bank-cash-economic-pack-indian-businesses-icons-9168977.png' />
          <div>
            <h4>Total Deposit</h4>
            <p>${parseFloat((totalDeposit), 10).toFixed(1).toString()}</p>
          </div>
        </div>
        <div className='dashboard-card'>
          <img src='https://cdn3d.iconscout.com/3d/premium/thumb/atm-withdrawal-3d-icon-download-in-png-blend-fbx-gltf-file-formats--cash-dispence-machine-dispencing-banking-pack-business-icons-3374139.png' />
          <div>
            <h4>Total Withdraw</h4>
            <p>${parseFloat((totalAmountOfWithdraw), 10).toFixed(2).toString()}</p>
          </div>
        </div>
        <div className='dashboard-card'>
          <img src='https://cdn3d.iconscout.com/3d/premium/thumb/referral-3d-icon-download-in-png-blend-fbx-gltf-file-formats--refferal-money-flow-financial-network-internet-marketing-pack-seo-web-icons-8135908.png' />
          <div>
            <h4>Referral Profit</h4>
            <p>$0</p>
          </div>
        </div>
        <div className='dashboard-card'>
          <img src='https://cdn3d.iconscout.com/3d/premium/thumb/deposit-3d-icon-download-in-png-blend-fbx-gltf-file-formats--money-finance-bank-cash-economic-pack-indian-businesses-icons-9168977.png' />

          <div>
            <h4>Direct Reward</h4>
            <p>${parseFloat(totalAmountOfDirect, 10).toFixed(2).toString()}</p>
          </div>
        </div>
        <div className='dashboard-card'>
          <img src='https://cdn3d.iconscout.com/3d/premium/thumb/dollar-exchange-3d-icon-download-in-png-blend-fbx-gltf-file-formats--money-currency-foreign-conversion-investment-pack-business-icons-5858264.png?f=webp' />
          <div>
            <h4>Total Profit</h4>
            <p>${allCurrentProfit && allCurrentProfit[0] && allCurrentProfit[0].totalProfit !== null && allCurrentProfit[0].totalProfit !== undefined ? allCurrentProfit[0].totalProfit.toFixed(2) : '0'}</p>

          </div>
        </div>

      </div>
      <Tabs />
    </div>
  )
}



const Tabs = () => {
  const [activeTab, setActiveTab] = useState("Tab1");

  const renderContent = () => {
    switch (activeTab) {
      case "Tab1":
        return <DepositTable />;
      case "Tab2":
        return <WithdrawTable />;
      case "Tab3":
        return <ReferralTable />;
      case "Tab4":
        return <DriectTable />;
      default:
        return null;
    }
  };

  return (
    <div className='tab-container' style={{ marginTop: '4rem' }}>
      <div className="tab-buttons">
        <button
          className={activeTab === "Tab1" ? "active" : ""}
          onClick={() => setActiveTab("Tab1")}
        >
          Deposit
        </button>
        <button
          className={activeTab === "Tab2" ? "active" : ""}
          onClick={() => setActiveTab("Tab2")}
        >
          Withdraw
        </button>
        <button
          className={activeTab === "Tab3" ? "active" : ""}
          onClick={() => setActiveTab("Tab3")}
        >
          Referral
        </button>
        <button
          className={activeTab === "Tab4" ? "active" : ""}
          onClick={() => setActiveTab("Tab4")}
        >
          Direct Reward
        </button>
        {/* <button
          className={activeTab === "Tab5" ? "active" : ""}
          onClick={() => setActiveTab("Tab5")}
        >
          History
        </button> */}
      </div>
      <div className=" referralBox">{renderContent()}</div>
    </div>
  );
};
export default Dashboard