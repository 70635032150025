import React, { useEffect, useState } from 'react'
import { BASEIMAGEURL, useAppContext } from '../../Context/AppContext';
import ViewDeposit from '../Components/ViewDeposit/ViewDeposit';
import axios from 'axios';

const Deposit = () => {
    const { allDeposit } = useAppContext();
    const [isView, setIsView] = useState(false);
    const [user, setUser] = useState(null);

    const [searchUser, setSearchUser] = useState(allDeposit);
    useEffect(() => {
        setSearchUser(allDeposit);
    }, [allDeposit]);

    const search = (text) => {
        if (text.length > 0) {
            const search = allDeposit.filter(
                (e) =>
                    (e.username.toLowerCase().includes(text.toLowerCase()) ||
                        e.email.toLowerCase().includes(text.toLowerCase()) ||
                        e.country.toLowerCase().includes(text.toLowerCase())) &&
                    e
            );
            // console.log(search);
            if (search.length > 0) {
                setSearchUser(search);
            } else {
                setSearchUser([]);
            }
        } else {
            setSearchUser(allDeposit);
        }
    };

    const [amountUSD, setAmountUSD] = useState(null);
    const [convertedAmount, setConvertedAmount] = useState(null);
    const onConvert = async (amount, bankType) => {
        if (bankType == "CRYPTO") {
            setAmountUSD(`${amount}$`)
            setConvertedAmount(`${amount}$`)

        } else {
            const res = await convertCurrency(amount, "USDT", "PKR");
            if (res) {
                setAmountUSD(`${amount}PKR`)
                setConvertedAmount(`${res?.convertedAmount?.toFixed(2)}$`)
            }
        }
    }



    const convertCurrency = async (amount, fromCurrency, toCurrency) => {
        const API_KEY = 'cur_live_0GnM3441KwjkDAnyPyjTWtvwBmo4SnTZpdJvBird';
        const API_URL = `https://api.currencyapi.com/v3/latest?apikey=${API_KEY}`;
        try {
            // Fetch exchange rates
            const response = await axios.get(API_URL);
            const rates = response.data.data;
            // Get exchange rates for the currencies
            const fromRate = rates[fromCurrency]?.value;
            const toRate = rates[toCurrency]?.value;
            if (!fromRate || !toRate) {
                throw new Error('Invalid currency code');
            }
            // console.log((fromRate));

            // Calculate the converted amount
            const convertedAmount = (fromRate / toRate) * amount;
            return {
                convertedAmount,
                fromRate,
                toRate,
            };
        } catch (error) {
            console.error('Error converting currency:', error.message);
            return null;
        }
    };
    return (
        <div>
            <div className="search-box">
                <input
                    placeholder="Search here"
                    onChange={(e) => search(e.target.value)}
                />
                {/* <button>Add New</button> */}
            </div>
            <div className="table-box">
                <table className=" market-table">
                    <thead className="table-head">
                        <tr className="table-row table-title">
                            <th className="table-heading" scope="col">
                                #
                            </th>
                            <th className="table-heading" scope="col">
                                UserName
                            </th>
                            <th className="table-heading" scope="col">
                                Plan
                            </th>
                            <th className="table-heading" scope="col">
                                Amount
                            </th>
                            <th className="table-heading" scope="col">
                                Image
                            </th>
                            <th className="table-heading" scope="col">
                                Status
                            </th>
                            <th className="table-heading" scope="col">
                                Date
                            </th>
                            <th className="table-heading" scope="col">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {searchUser == null ? (
                            <p>Please Wait...</p>
                        ) : searchUser.length > 0 ? (
                            searchUser?.map((d, k) => {
                                return (
                                    <tr className="table-row" key={k}>
                                        <td>{k + 1}</td>
                                        <td>{d?.user?.username}</td>
                                        <td>{d?.plan?.invest?.from != 0 && d?.plan?.invest?.from + "$"} ({d?.plan.type})</td>
                                        <td>{d?.amount} {d?.coins?.bankType == "CRYPTO" ?"$":"PKR"} </td>
                                        <td>
                                            <img src={`${BASEIMAGEURL + d?.image}`} style={{ width: 70, height: 70, borderRadius: 5 }} />
                                        </td>
                                        <td>{d?.userplan?.isApproved}</td>
                                        <td>{d?.createdAt.split("T")[0]}</td>

                                        <td>
                                            <i
                                                onClick={async() => {
                                                    setUser(d);
                                                   await onConvert(d.amount, d?.coins?.type)
                                                    setIsView(true);
                                                }}
                                                className="bx bx-edit"
                                                style={{ cursor: "pointer" }}
                                            />
                                            {/* <i
                                                className="bx bx-trash"
                                                style={{ cursor: "pointer" }}
                                            /> */}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <p>No Data Founded</p>
                        )}
                    </tbody>
                </table>
            </div>
            <ViewDeposit isShow={isView} amount={amountUSD} convertedAmount={convertedAmount} onClose={() => setIsView(false)} data={user} />
        </div>
    );
}

export default Deposit